import { /*baseClient,*/ API, authClient } from '../../utils/index';
import { uploadSingleFile } from '../files';

// Get all retailers
export const getRetailers = () => {
	return authClient().get(`${API}/retailers`);
};

// Get retailer by id
export const getRetailerById = (id) => {
	return authClient().get(`${API}/retailers/${id}`);
};

// Get retailer by name
export const getRetailerByName = (name) => {
	return authClient().get(`${API}/retailers/name/${name}`);
};

// Update retailer
export const updateRetailer = async (id, retailer, logo) => {
	if (logo) {
		const response = await uploadSingleFile(logo, 'retailers');
		retailer.logo = response.data.fileURL;
	}
	return authClient().put(`${API}/retailers/${id}`, retailer);
};

// Change status retailer
export const changeStatusRetailer = async (id, status) => {
	return authClient().put(
		`${API}/retailers/change-status/${id}?status=${status}`
	);
};

// Save retailer
export const postRetailer = async (retailer, logo) => {
	let response = await uploadSingleFile(logo, 'retailers');
	retailer.logo = response.data.fileURL;
	return authClient().post(`${API}/retailers`, retailer);
};
