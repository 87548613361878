import { /*baseClient,*/ API, authClient } from '../../utils/index';

// Get all users
export const getUsers = () => {
	return authClient().get(`${API}/user`);
};

// Get user by id
export const getUserById = (id) => {
	return authClient().get(`${API}/user/${id}`);
};

// Delete user
export const deleteUser = (id) => {
	return authClient().delete(`${API}/user/${id}`);
};

// Change status user
export const changeStatusUser = async (id, status) => {
	return authClient().put(`${API}/user/change-status/${id}?status=${status}`);
};

// Update user
export const updateUser = async (id, user) => {
	return authClient().put(`${API}/user/${id}`, user);
};

// Save user
export const postUser = async (user) => {
	return authClient().post(`${API}/user`, user);
};

export const getUserRetailerByEmail = (email) => {
	return authClient().get(`${API}/user/user-retailer/${email}`);
};

export const getUserRetailerByRetailer = (id) => {
	return authClient().get(`${API}/user/retailers/${id}`);
};
