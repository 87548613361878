import React, { useState, useEffect } from 'react'
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	FormControl,
	InputLabel,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useHistory, useParams } from 'react-router-dom'
import {
	getAppraisalById,
	postAppraisal,
	updateAppraisal,
} from '../../../../api/appraisals'
import { getUsers } from '../../../../api/users'
import { getRetailers } from '../../../../api/retailers'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import EditIcon from '@material-ui/icons/Edit'
import { APPRAISAL_STATUS } from './AppraisalsPage'

import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
registerLocale('es', es)

function getEmptyAppraisal() {
	return {
		client: '',
		products: [],
		code: '',
		retailerUser: '',
		status: 'pending',
		offerSent: false,
		//price: 0,
		//commission: 0,
		comments: '',
	}
}

export function getRetailersData(users, retailers) {
	let data = []
	for (let i = 0; i < users.length; ++i) {
		const elem = {
			...users[i],
			retailer: retailers.find((item) => item._id === users[i].retailer)?.name,
		}
		data = data.concat(elem)
	}
	return data
}

export default function EditAppraisalsPage() {
	const [appraisal, setAppraisal] = useState(getEmptyAppraisal())
	const [clients, setClients] = useState([])
	const [retailerUsers, setRetailerUsers] = useState([])
	const [date, setDate] = useState(new Date())
	const appraisalId = useParams().id
	const history = useHistory()

	const getLabel = (option, field) => {
		if (option[field]) {
			let obj = {}
			if (field === 'client') {
				obj = clients.find((client) => client._id === option[field])
				return `${obj?.name} ${obj?.surname} (${obj?.email})`
			}
			obj = retailerUsers.find((user) => user._id === option[[field]])
			return `${obj?.name} ${obj?.surname} - ${obj?.retailer}`
		} else {
			if (option[field] === '') return ''
			return `${option?.name} ${option?.surname} ${
				option?.retailer ? ` - ${option.retailer}` : `(${option?.email})`
			}`
		}
	}

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton()

	useEffect(() => {
		getRetailers()
			.then((resp) => {
				if (resp.status === 200) {
					getUsers()
						.then((res) => {
							if (res.status === 200) {
								setClients(
									res.data.filter(
										(item) => item.active && item.role === 'seller'
									)
								)

								setRetailerUsers(
									getRetailersData(
										res.data.filter(
											(item) => item.active && item.role === 'retailer-user'
										),
										resp.data
									)
								)
								if (appraisalId) {
									getAppraisalById(appraisalId)
										.then((res) => {
											if (res.status === 200) {
												setAppraisal(res.data)
												setDate(new Date(res.data.createdAt))
												disableLoadingData()
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'No se ha podido obtener la tasación.',
											})
											history.push('/tasaciones')
										})
								}
								if (!appraisalId) {
									disableLoadingData()
									return
								}
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'No se han podido obtener los usuarios.',
							})
							history.push('/tasaciones')
						})
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los retailers.',
				})
				history.push('/tasaciones')
			})
	}, [appraisalId, disableLoadingData, history])

	function saveAppraisal() {
		if (!appraisalId) {
			postAppraisal(appraisal)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardada!',
							customMessage: 'La tasación se ha creado correctamente.',
						})
						history.push('/tasaciones')
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar la tasación.',
					})
				})
		} else {
			updateAppraisal(appraisalId, appraisal)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Guardada!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						})
						history.push('/tasaciones')
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					})
				})
		}
	}

	const handleChange = (element) => (event) => {
		setAppraisal({ ...appraisal, [element]: event.target.value })
	}

	const handleSelectChange = (selected, element) => {
		if (selected)
			setAppraisal({
				...appraisal,
				[element]: selected?._id || selected?.id,
			})
	}

	if (isLoadingData) return <ContentSkeleton />
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos de la tasación'></CardHeader>
					<CardBody>
						<div style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Fecha solicitud
								<DatePicker
									className='m-5'
									locale='es'
									dateFormat='dd/MM/yyyy HH:mm'
									placeholderText='Fecha Hora'
									selected={date}
									popperPlacement='top-end'
									showTimeSelect
									timeIntervals={15}
									timeCaption='Hora'
									onChange={(date) => {
										setDate(date)
										setAppraisal({
											...appraisal,
											createdAt: date.toISOString(),
										})
									}}
								/>
							</InputLabel>
						</div>
						<FormControlLabel
							control={
								<Checkbox
									checked={appraisal.offerSent}
									onChange={() =>
										setAppraisal({
											...appraisal,
											offerSent: !appraisal.offerSent,
										})
									}
									name='offerSent'
								/>
							}
							label='Oferta enviada'
						/>
						{appraisal.code && (
							<TextField
								id={`code`}
								label='Código'
								value={appraisal.code}
								InputLabelProps={{
									shrink: true,
								}}
								margin='normal'
								variant='outlined'
								disabled
								style={{ zIndex: 0 }}
							/>
						)}
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<Autocomplete
								options={APPRAISAL_STATUS}
								getOptionLabel={(option) => {
									return option?.label || option?.status
								}}
								onChange={(event, selected) => {
									handleSelectChange(selected, 'status')
								}}
								disableClearable
								value={APPRAISAL_STATUS.find((s) => s.id === appraisal.status)}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Seleccionar estado'
										variant='outlined'
									/>
								)}
							/>
						</FormControl>
						<br />
						<br />
						<FormControl className='mt-3' style={{ width: '100%' }}>
							<Autocomplete
								options={clients}
								getOptionLabel={(option) => getLabel(option, 'client')}
								onChange={(event, selected) => {
									handleSelectChange(selected, 'client')
								}}
								value={appraisal}
								disableClearable
								renderInput={(params) => (
									<TextField
										{...params}
										label='Seleccionar vendedor'
										variant='outlined'
									/>
								)}
							/>
						</FormControl>
						<br />
						<br />
						<FormControl className='mt-3' style={{ width: '100%' }}>
							<Autocomplete
								options={retailerUsers}
								getOptionLabel={(option) => getLabel(option, 'retailerUser')}
								onChange={(event, selected) => {
									handleSelectChange(selected, 'retailerUser')
								}}
								value={appraisal}
								disableClearable
								renderInput={(params) => (
									<TextField
										{...params}
										label='Seleccionar gestor (usuario de retailer)'
										variant='outlined'
									/>
								)}
							/>
						</FormControl>
						<br />
						<br />

						<TextField
							id={`comments`}
							label='Observaciones'
							value={appraisal.comments}
							onChange={handleChange('comments')}
							InputLabelProps={{
								shrink: true,
							}}
							multiline={true}
							rows={5}
							margin='normal'
							variant='outlined'
							style={{ zIndex: 0 }}
						/>
						<br />
						<br />
						{appraisal.products_data?.length ? (
							<h6>Productos asociados ({appraisal.products_data?.length}): </h6>
						) : null}
						{appraisal.products_data?.map((product) => (
							<div
								className='card-client-list'
								key={product._id}
								style={{ cursor: 'pointer' }}
								onClick={() => history.push(`/editar-producto/${product._id}`)}
							>
								<div
									style={{
										position: 'absolute',
										top: '5px',
										right: '5px',
									}}
								>
									<EditIcon />
								</div>
								<div className='row'>
									<div className='col-md-4'>
										<b>Número de serie:</b>
										{` ${product.serialNumber || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Referencia:</b>
										{` ${product.reference || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Código:</b>
										{` ${product.code || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Estado:</b>
										{` ${product.status || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Número de factura:</b>
										{` ${product.invoiceNumber || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Expectativa venta:</b>
										{` ${product.priceExpectation || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Marca:</b>
										{` ${product.brand || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Modelo:</b>
										{` ${product.model || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Material de la caja:</b>
										{` ${product.boxMaterial || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Material de la correa:</b>
										{` ${product.strapMaterial || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Movimiento:</b>
										{` ${product.movement || ''}`}
									</div>
									<div className='col-md-4'>
										<b>Certificado de garantía:</b>
										{` ${product.warrantyCertificate ? 'Sí' : 'No'}`}
									</div>
									<div className='col-md-4'>
										<b>Manual de instrucciones:</b>
										{` ${product.instructions ? 'Sí' : 'No'}`}
									</div>
									<div className='col-md-4'>
										<b>Estuche original:</b>
										{` ${product.originalCase ? 'Sí' : 'No'}`}
									</div>
								</div>
							</div>
						))}
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/tasaciones')}
					variant='outlined'
					style={{ marginRight: '20px' }}
				>
					Atrás
				</Button>
				<Button
					onClick={() => saveAppraisal()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}
				>
					Guardar tasación
				</Button>
			</>
		)
}
