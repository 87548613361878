import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const PreviewDialog = (props) => {
	const {
		title,
		open,
		setOpen,
		children,
		inCall,
		deleteImage,
		downloadImage,
	} = props;

	return (
		<Dialog
			fullWidth={true}
			maxWidth='lg'
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='confirm-dialog'>
			<DialogTitle id='confirm-dialog'>{title}</DialogTitle>
			<DialogContent /* style={{ display: 'flex', justifyContent: 'center' }} */
			>
				{children}
			</DialogContent>
			<DialogActions>
				{inCall && (
					<>
						<Button
							variant='outlined'
							onClick={() => {
								downloadImage();
							}}
							color='primary'>
							Descargar
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								deleteImage();
							}}
							color='default'
							startIcon={<DeleteIcon />}>
							Eliminar
						</Button>
					</>
				)}
				<Button
					variant='outlined'
					onClick={() => {
						setOpen(false);
					}}
					color='default'>
					{inCall ? 'Cerrar' : 'Ok!'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PreviewDialog;
