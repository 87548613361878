import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	getRetailerById,
	postRetailer,
	updateRetailer,
} from '../../../../api/retailers';
import { getCountries } from '../../../../api/countries';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { SERVER_URL } from '../../../../utils';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { CurrencySymbolMap } from '../../../../constants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getEmptyRetailer() {
	return {
		name: '',
		logo: '',
		cif: '',
		country: '',
		address: '',
		postalCode: '',
		city: '',
		province: '',
		itp: '0.0',
		commission: '0.0',
		comments: '',
		adminComments: '',
		active: true,
		VATNumber: '',
		currency: '',
	};
}

export default function EditRetailersPage() {
	const [retailer, setRetailer] = useState(getEmptyRetailer());
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [countries, setCountries] = useState([]);
	const retailerId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getCountries()
			.then((res) => {
				if (res.status === 200) {
					setCountries(res.data.filter((item) => item.active));
					if (!retailerId) {
						disableLoadingData();
						return;
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los países.',
				});
				history.push('/retailers');
			});
		if (retailerId) {
			getRetailerById(retailerId)
				.then((res) => {
					if (res.status === 200) {
						setRetailer(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido obtener el retailer.',
					});
					history.push('/retailers');
				});
		}
	}, [retailerId, disableLoadingData, history]);

	function saveRetailer() {
		if (typeof retailer.itp === 'string') {
			retailer.itp = retailer.itp.includes(',')
				? retailer.itp.replace(',', '.')
				: retailer.itp;
			if (isNaN(retailer.itp)) {
				alertError({ error: null, customMessage: 'ITP debe ser un número.' });
				return;
			}
			retailer.itp = parseFloat(retailer.itp);
		}
		if (typeof retailer.commission === 'string') {
			retailer.commission = retailer.commission.includes(',')
				? retailer.commission.replace(',', '.')
				: retailer.commission;
			if (isNaN(retailer.commission)) {
				alertError({
					error: null,
					customMessage: 'La comisión debe ser un número.',
				});
				return;
			}
			retailer.commission = parseFloat(retailer.commission);
		}
		if (!retailerId) {
			if (!selectedImage) {
				alertError({
					error: null,
					customMessage: 'Por favor, suba una imagen.',
				});
				return;
			}
			postRetailer(retailer, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardado!',
							customMessage: 'El retailer se ha creado correctamente.',
						});
						history.push('/retailers');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar el retailer.',
					});
				});
		} else {
			updateRetailer(retailerId, retailer, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Guardado!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						});
						history.push('/retailers');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		console.log(event.target.value);
		setRetailer({ ...retailer, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos del retailer'></CardHeader>
					<CardBody>
						<TextField
							id={`cif`}
							label='CIF/NIF'
							value={retailer.cif}
							onChange={handleChange('cif')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`name`}
							label='Denominación Social'
							value={retailer.name}
							onChange={handleChange('name')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								País
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={retailer.country}
								onChange={handleChange('country')}
								MenuProps={MenuProps}>
								{countries.map((option) => (
									<MenuItem key={option.name} value={option.name}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Divisa
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={retailer.currency}
								onChange={handleChange('currency')}
								MenuProps={MenuProps}>
								{Object.keys(CurrencySymbolMap).map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`address`}
							label='Dirección'
							value={retailer.address}
							onChange={handleChange('address')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`postalCode`}
							label='CP'
							value={retailer.postalCode}
							onChange={handleChange('postalCode')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`city`}
							label='Población'
							value={retailer.city}
							onChange={handleChange('city')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`province`}
							label='Província'
							value={retailer.province}
							onChange={handleChange('province')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`itp`}
							label='ITP'
							value={retailer.itp}
							onChange={handleChange('itp')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`commission`}
							label='Comisión'
							value={retailer.commission}
							onChange={handleChange('commission')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`VATNumber`}
							label='VAT'
							value={retailer.VATNumber}
							onChange={handleChange('VATNumber')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`comments`}
							label='Observaciones'
							value={retailer.comments}
							onChange={handleChange('comments')}
							multiline={true}
							rows={5}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`adminComments`}
							label='Observaciones del Admin'
							value={retailer.adminComments}
							onChange={handleChange('adminComments')}
							multiline={true}
							rows={5}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<br />
						<br />
						<label htmlFor='upload-image'>
							<input
								style={{ display: 'none' }}
								id='upload-image'
								name='upload-image'
								type='file'
								accept={'image/*'}
								onChange={(e) => setSelectedImage(e.target.files[0])}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || retailer.logo !== ''
									? 'Cambiar logo'
									: 'Subir logo'}
							</Button>
						</label>
						{(selectedImage || retailer.logo !== '') && (
							<>
								<Button
									onClick={() => setOpenPreviewDialog(true)}
									variant='outlined'>
									Ver logo
								</Button>
								<PreviewDialog
									title={'Ver logo'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${retailer.imageURL}`
									}
								/>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={retailer.active}
									onChange={() =>
										setRetailer({ ...retailer, active: !retailer.active })
									}
									name='checkActive'
								/>
							}
							label='Activo'
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/retailers')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveRetailer()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar retailer
				</Button>
			</>
		);
}
