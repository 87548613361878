import React, { useEffect, useState } from 'react';
import Table, { dateFormatter } from '../../../../components/tables/table';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { getAppraisals } from '../../../../../api/appraisals';
import { getRetailers } from '../../../../../api/retailers';
import { getUsers } from '../../../../../api/users';
import { alertError } from '../../../../../utils/logger';
import xlsx from 'json-as-xlsx';
import { getRetailersData } from '../../appraisals/EditAppraisalsPage';
import ExportFiltersDialog from '../../../../components/dialogs/ExportFiltersDialog';
import { CurrencySymbolMap } from '../../../../../constants';

function getData(appraisals, retailers, userretailers) {
	let data = [];
	for (let i = 0; i < appraisals.length; ++i) {
		const elem = {};
		elem.createdAt = appraisals[i].createdAt;
		elem.code = appraisals[i].code;
		elem.price = appraisals[i].price; //TODO: Sumar todos los importes de las ofertas de los productos de la tasación
		elem.status = appraisals[i].status;
		elem.user_retailer_id =
			userretailers.find((item) => item._id === appraisals[i].retailerUser)
				?._id || '----';
		elem.userretailer =
			userretailers.find((item) => item._id === appraisals[i].retailerUser)
				?.name || '----';
		elem.retailer_id =
			userretailers.find((item) => item._id === appraisals[i].retailerUser)
				?.retailer || '----';
		let retailer = retailers.find((item) => item._id === elem.retailer_id);
		elem.retailer = retailer?.name || '----';
		elem.products = appraisals[i].products
			.map(
				({ reference, brand, model }) =>
					`${reference || '--'}: ${brand || '--'} ${model || '--'}`
			)
			.join('|');
		elem.priceValue =
			appraisals[i].products.reduce(
				(total, currentValue) => (total = total + currentValue.price),
				0
			) || 0;

		//precio total
		let currencyCode = CurrencySymbolMap[retailer?.currency] || '€';
		elem.price = elem.priceValue + currencyCode;

		//porcentaje retailer
		elem.retailerPorc =
			retailers.find(
				(item) =>
					item._id ===
					userretailers.find((item) => item._id === appraisals[i].retailerUser)
						?.retailer
			)?.commission || 0;
		//cálculo importe retailer
		elem.retailerCom = ((elem.priceValue / 100) * elem.retailerPorc).toFixed(2);
		//formatear importe retailer + porcentaje retailer
		elem.retailercomission =
			elem.retailerCom + currencyCode + ' (' + elem.retailerPorc + '%)';

		//porcentaje usuario retailer
		elem.userretailerPorc =
			userretailers.find((item) => item._id === appraisals[i].retailerUser)
				?.commission || 0;
		//cálculo importe usuario retailer
		elem.userretailerCom = (
			(elem.priceValue / 100) *
			elem.userretailerPorc
		).toFixed(2);
		//formatear importe usuario retailer + porcentaje usuario retailer
		elem.userretailercomission =
			elem.userretailerCom + currencyCode + ' (' + elem.userretailerPorc + '%)';

		elem.id = appraisals[i]._id;
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

function exportExcel(fileName, rows) {
	const content = [];
	rows.forEach((row) => {
		content.push({ ...row, createdAt: dateFormatter(row.createdAt) });
	});

	let xlsxFile = [
		{
			sheet: 'Appraisals_report',
			columns: [
				{ label: 'FECHA VENTA', value: 'createdAt' },
				{ label: 'RETAILER', value: 'retailer' },
				{ label: 'VENDEDOR', value: 'userretailer' },
				{ label: 'TASACION', value: 'code' },
				{ label: 'PRODUCTOS', value: 'products' },
				{ label: 'IMPORTE RETAILER', value: 'retailercomission' },
				{ label: 'IMPORTE VENDEDOR', value: 'userretailercomission' },
				{ label: 'IMPORTE VENTA', value: 'price' },
			],
			content,
		},
	];
	const settings = {
		fileName,
		extraLength: 10,
	};
	xlsx(xlsxFile, settings);
}

export default function CommissionsReportsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openExportDialog, setOpenExportDialog] = useState(false);
	const [retailers, setRetailers] = useState([]);
	const [usersRetailers, setUsersRetailers] = useState([]);

	const columns = [
		{
			dataField: 'createdAt',
			text: 'Fecha venta',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{ dataField: 'userretailer', text: 'Vendedor', sort: true },
		{ dataField: 'code', text: 'Tasación', sort: true },
		{ dataField: 'products', text: 'Productos', sort: true },
		{ dataField: 'retailercomission', text: 'Importe retailer', sort: true },
		{
			dataField: 'userretailercomission',
			text: 'Importe vendedor',
			sort: true,
		},
		{ dataField: 'price', text: 'Importe venta', sort: true },
		//TODO: añadir nombre + apellidos + email CLIENTE
	];

	useEffect(() => {
		getAppraisals()
			.then((res) => {
				if (res.status === 200) {
					getRetailers()
						.then((response) => {
							getUsers()
								.then((resusers) => {
									if (res.status === 200) {
										setData(getData(res.data, response.data, resusers.data));
										setRetailers(response.data);
										setUsersRetailers(
											getRetailersData(
												resusers.data.filter(
													(item) => item.active && item.role === 'retailer-user'
												),
												response.data
											)
										);
										setRefresh(false);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se han podido obtener los usuarios de retailers de la base de datos.',
									});
								});
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener los retailers de la base de datos.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener las tasaciones de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Informe de comisiones'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => setOpenExportDialog(true)}>
							Export excel
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
			<ExportFiltersDialog
				open={openExportDialog}
				setOpen={setOpenExportDialog}
				title='Exportar informe de comisiones'
				retailers={retailers}
				usersRetailers={usersRetailers}
				data={data}
				onConfirm={(excelData) => exportExcel('CommissionsReport', excelData)}
			/>
		</>
	);
}
