import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all movements
export const getMovements = () => {
    return authClient().get(`${API}/movements`)
}

// Get movement by id
export const getMovementById = (id) => {
    return authClient().get(`${API}/movements/${id}`)
}

// Update movement
export const updateMovement = async (id, movement) => {
    return authClient().put(`${API}/movements/${id}`, movement)
}

// Save movement
export const postMovement = async (movement) => {
    return authClient().post(`${API}/movements`, movement)
}

// Change status movement
export const changeStatusMovement = async (id, status) => {
    return authClient().put(`${API}/movements/change-status/${id}?status=${status}`)
}

// Delete movement
export const deleteMovement = (id) => {
    return authClient().delete(`${API}/movements/${id}`)
}

