import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { dateFormatter } from '../tables/table'

const AppointmentInfoDialog = (props) => {
	const { open, setOpen, appointment, onConfirm } = props

	return (
		<Dialog
			open={open}
			onClose={() => {}}
			aria-labelledby="confirm-dialog">
			<DialogTitle id="confirm-dialog">Detalle de la cita</DialogTitle>
			<DialogContent>
				<p><strong>Fecha de la cita: </strong>{dateFormatter(appointment?.start)}</p>
				<p><strong>Nombre: </strong>{appointment?.name}</p>
				<p><strong>Apellidos: </strong>{appointment?.surname}</p>
				<p><strong>Teléfono: </strong>{appointment?.phone}</p>
				<p><strong>Email: </strong>{appointment?.email}</p>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						setOpen(false)
						onConfirm()
					}}
				>
					Ver cita
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default AppointmentInfoDialog
