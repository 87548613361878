import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all product types
export const getProductTypes = () => {
    return authClient().get(`${API}/product-types`)
}

// Get product type by id
export const getProductTypeById = (id) => {
    return authClient().get(`${API}/product-types/${id}`)
}

// Update product type
export const updateProductType = async (id, productType) => {
    return authClient().put(`${API}/product-types/${id}`, productType)
}

// Save product type
export const postProductType = async (productType) => {
    return authClient().post(`${API}/product-types`, productType)
}

// Change status product type
export const changeStatusProductType = async (id, status) => {
    return authClient().put(`${API}/product-types/change-status/${id}?status=${status}`)
}

// Delete product type
export const deleteProductType = (id) => {
    return authClient().delete(`${API}/product-types/${id}`)
}

