import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
	dateFormatter,
} from '../../../components/tables/table';
import {
	getAppointments,
	deleteAppointment,
	changeStatusAppointment,
} from '../../../../api/appointments';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmIcon from '@material-ui/icons/EventAvailable';
import CancelIcon from '@material-ui/icons/EventBusy';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import AppointmentInfoDialog from '../../../components/dialogs/AppointmentInfoDialog';
import { alertError, alertSuccess } from '../../../../utils/logger';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { getUsers } from '../../../../api/users';

function getListData(appointments, users) {
	let data = [];
	for (let i = 0; i < appointments.length; ++i) {
		const user = users.find((item) => item._id === appointments[i].client);
		const elem = {};
		elem.date = appointments[i].date;
		elem.client = user ? user.name + ' ' + user.surname : '--';
		elem.confirmed = appointments[i].confirmed;
		elem.id = appointments[i]._id;
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

function getCalendarData(appointments, users) {
	let data = [];
	for (let i = 0; i < appointments.length; ++i) {
		const elem = {};
		const user = users.find((item) => item._id === appointments[i].client);
		elem.start = appointments[i].date;
		elem.name = user?.name;
		elem.surname = user?.surname;
		elem.phone = user?.phone;
		elem.email = user?.email;
		elem.title = user ? user.name + ' ' + user.surname : '--';
		elem.id = appointments[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function AppointmentsPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [users, setUsers] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [appointmentId, setAppointmentId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [openInfoDialog, setOpenInfoDialog] = useState(false);
	const [confirmedAppointment, setConfirmedAppointment] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [listView, setListView] = useState(true);

	function buttonFormatter(cell) {
		const confirmed = !displayData.filter((item) => item.id === cell)[0]
			.confirmed;
		return (
			<>
				<Tooltip title={confirmed ? 'Confirmar' : 'Cancelar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setAppointmentId(cell);
							setOpenConfirmDialog(1);
							setConfirmedAppointment(confirmed);
						}}>
						{confirmed ? <ConfirmIcon /> : <CancelIcon />}
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/editar-cita/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setAppointmentId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: 'date',
			text: 'Fecha Hora (España)',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'client', text: 'Nombre y apellidos', sort: true },
		{
			dataField: 'confirmed',
			text: 'Confirmada',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getAppointments()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener las citas de la base de datos.',
				});
			});
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setUsers(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener las usuarios de la base de datos.',
				});
			});
	}, [refresh]);

	useEffect(() => {
		if (!data.length || !users.length) return;
		setDisplayData(
			listView ? getListData(data, users) : getCalendarData(data, users)
		);
	}, [listView, data, users]);

	const handleEventClick = (clickInfo) => {
		setAppointmentId(clickInfo.event.id);
		setOpenInfoDialog(true);
	};

	return (
		<>
			<Card>
				<CardHeader
					title={listView ? 'Listado de citas' : 'Calendario de citas'}>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary mr-2'
							onClick={() => history.push('/editar-cita')}>
							Añadir cita
						</button>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => setListView(!listView)}>
							{listView ? 'Vista calendario' : 'Vista listado'}
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					{listView ? (
						<Table data={displayData} columns={columns} />
					) : (
						<FullCalendar
							locale={esLocale}
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
							headerToolbar={{
								left: 'prevYear,prev,next,nextYear,today',
								center: 'title',
								right: 'dayGridMonth,timeGridWeek,timeGridDay',
							}}
							initialView='dayGridMonth'
							weekends={false}
							events={displayData}
							eventClick={handleEventClick}
						/>
					)}
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(confirmedAppointment ? 'confirmar' : 'cancelar') +
							' esta cita?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusAppointment(appointmentId, confirmedAppointment)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: confirmedAppointment
												? '¡Confirmada!'
												: '¡Cancelada!',
											customMessage:
												'Cita ' +
												(confirmedAppointment ? 'confirmada' : 'cancelada') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(confirmedAppointment ? 'confirmar' : 'cancelar') +
											' la cita.',
									});
								});
						}}>
						{
							'Se enviará un email al vendedor informándole del estado de la cita'
						}
					</ConfirmDialog>

					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar esta cita?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteAppointment(appointmentId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrada!',
											customMessage: 'Cita eliminada correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar la cita.',
									});
								});
						}}
					/>
					<AppointmentInfoDialog
						open={openInfoDialog}
						setOpen={setOpenInfoDialog}
						appointment={displayData.find((item) => item.id === appointmentId)}
						onConfirm={() => history.push('/editar-cita/' + appointmentId)}
					/>
				</CardBody>
			</Card>
		</>
	);
}
