import React, { useState } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'

const languages = [
    { key: 'es', value: 'Español' },
    { key: 'en', value: 'English' }
]

function MultilanguageTabBlock({ multilanguageTabContent }) {
	const [tab, setTab] = useState(0)

	const handleChangeTabs = (_, newValue) => {
		setTab(newValue)
	}

	return (
		<>
			<AppBar position="static" color="default" key="appbar">
				<Tabs
					value={tab}
					onChange={handleChangeTabs}
					variant="scrollable"
					scrollButtons="auto"
					key="tabs"
				>
					{languages.map((lang) => (
						<Tab
							key={lang.key}
							label={lang.value} />
					))}
				</Tabs>
			</AppBar>
			{languages.map((lang, index) =>
				tab === index && (
					<div key={`tabContainer-${index}`}>
						{multilanguageTabContent(lang)}
					</div>
				)
			)}

		</>
	)
}

export default MultilanguageTabBlock