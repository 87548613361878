import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all countries
export const getCountries = () => {
    return authClient().get(`${API}/countries`)
}

// Get country by id
export const getCountryById = (id) => {
    return authClient().get(`${API}/countries/${id}`)
}

// Update country
export const updateCountry = async (id, country) => {
    return authClient().put(`${API}/countries/${id}`, country)
}

// Save country
export const postCountry = async (country) => {
    return authClient().post(`${API}/countries`, country)
}

// Change status country
export const changeStatusCountry = async (id, status) => {
    return authClient().put(`${API}/countries/change-status/${id}?status=${status}`)
}

// Delete country
export const deleteCountry = (id) => {
    return authClient().delete(`${API}/countries/${id}`)
}

