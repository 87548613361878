import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { useSkeleton } from "../../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../../utils/logger"
import { getBoxMaterialById, postBoxMaterial, updateBoxMaterial } from '../../../../../api/boxMaterials'
import MultilanguageTabBlock from '../../../../components/customFields/MultilanguageTabBlock'

function getEmptyBoxMaterial() {
  return {
    name: {},
    active: true
  }
}

export default function EditBoxMaterialsPage() {
  const [boxMaterial, setBoxMaterial] = useState(getEmptyBoxMaterial())
  const boxMaterialId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!boxMaterialId) {
      disableLoadingData()
      return
    }
    getBoxMaterialById(boxMaterialId).then(res => {
      if (res.status === 200) {
        setBoxMaterial(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener el material de la caja.' })
      history.push('/auxiliares/material-caja')
    })
  }, [boxMaterialId, disableLoadingData, history])

  function saveBoxMaterial() {
    if (!boxMaterialId) {
      postBoxMaterial(boxMaterial).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Guardado!', customMessage: 'El material de la caja se ha creado correctamente.' })
          history.push('/auxiliares/material-caja')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar el material de la caja.' })
      })
    } else {
      updateBoxMaterial(boxMaterialId, boxMaterial).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Guardado!', customMessage: 'Los cambios realizados se han guardado correctamente.' })
          history.push('/auxiliares/material-caja')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    let newField = boxMaterial[element]
    newField[lang] = event.target.value
    setBoxMaterial({ ...boxMaterial, [element]: newField })
  }

  const renderMultilanguageTabContent = lang => {
		return (
			<div style = {{ paddingTop: '5px' }}>
        <TextField
            id={`name`}
            label="Material de la caja"
            value={(boxMaterial.name && boxMaterial.name[lang.key]) || ''}
            onChange={handleChange('name', lang.key)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />

			</div>
		)
	}
  
  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Datos del material de la caja'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <br />
          <FormControlLabel
            control={<Checkbox checked={boxMaterial.active} onChange={() => setBoxMaterial({ ...boxMaterial, active: !boxMaterial.active })} name="checkActive" />}
            label="Activo"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/auxiliares/material-caja')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveBoxMaterial()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar material de la caja
      </Button>
    </>
  );
}

