import { /*baseClient,*/ API, authClient } from '../../utils/index';

// Get all calls
export const getCalls = () => {
	return authClient().get(`${API}/calls`);
};

// Get call by id
export const getCallById = (id) => {
	return authClient().get(`${API}/calls/${id}`);
};

// Update call
export const updateCall = async (call) => {
	return authClient().put(`${API}/calls/${call.id}`, call);
};

// Update call status
export const updateCallStatus = async (call) => {
	return authClient().put(`${API}/calls/update-status/${call.id}`, call);
};

// Save call
export const postCall = async (call) => {
	return authClient().post(`${API}/calls`, call);
};

// DElete call
export const deleteCall = (id) => {
	return authClient().delete(`${API}/calls/${id}`);
};
