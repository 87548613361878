import React, { useEffect, useState } from 'react';
import Table, { dateFormatter } from '../../../../components/tables/table';
import { getProducts } from '../../../../../api/products';
import { getAppraisals } from '../../../../../api/appraisals';
import { getRetailers } from '../../../../../api/retailers';
import { getUsers } from '../../../../../api/users';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { alertError } from '../../../../../utils/logger';
import xlsx from 'json-as-xlsx';
import { getRetailersData } from '../../appraisals/EditAppraisalsPage';
import ExportFiltersDialog from '../../../../components/dialogs/ExportFiltersDialog';
import { CurrencySymbolMap } from '../../../../../constants';

function getData(appraisals, products, retailers, userretailers) {
	let data = [];
	for (let i = 0; i < products.length; ++i) {
		const elem = {};
		elem.createdAt = products[i].createdAt;
		elem.appraisal = products[i].appraisal;
		elem.user_retailer_id =
			appraisals.find((item) => item._id === elem.appraisal)?.retailerUser ||
			'----';
		elem.userretailer =
			userretailers.find((item) => item._id === elem.user_retailer_id)?.name ||
			'----';
		elem.retailer_id =
			userretailers.find((item) => item._id === elem.user_retailer_id)
				?.retailer || '----';
		let retailer = retailers.find((item) => item._id === elem.retailer_id);
		let currencyCode = CurrencySymbolMap[retailer?.currency] || '€';
		elem.retailer = retailer?.name || '----';
		elem.productCode = products[i].reference;
		elem.brand = products[i].brand;
		elem.price = (products[i].price || 0) + currencyCode;
		elem.status = products[i]?.priceStatus || '----';
		elem.id = products[i]._id;
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

function exportExcel(fileName, rows) {
	const content = [];
	rows.forEach((row) => {
		content.push({ ...row, createdAt: dateFormatter(row.createdAt) });
	});

	let xlsxFile = [
		{
			sheet: 'Appraisals_report',
			columns: [
				{ label: 'FECHA ALTA', value: 'createdAt' },
				{ label: 'RETAILER', value: 'retailer' },
				{ label: 'VENDEDOR', value: 'userretailer' },
				{ label: 'REFERENCIA PRODUCTO', value: 'productCode' },
				{ label: 'MARCA', value: 'brand' },
				{ label: 'ESTADO', value: 'status' },
				{ label: 'IMPORTE TOTAL', value: 'price' },
			],
			content,
		},
	];
	const settings = {
		fileName,
		extraLength: 10,
	};
	xlsx(xlsxFile, settings);
}

export default function CommissionsReportsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openExportDialog, setOpenExportDialog] = useState(false);
	const [retailers, setRetailers] = useState([]);
	const [usersRetailers, setUsersRetailers] = useState([]);

	const columns = [
		{
			dataField: 'createdAt',
			text: 'Fecha alta',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{ dataField: 'userretailer', text: 'vendedor', sort: true },
		{ dataField: 'productCode', text: 'Referencia producto', sort: true },
		{ dataField: 'brand', text: 'Marca', sort: true },
		{ dataField: 'status', text: 'Estado', sort: true },
		{ dataField: 'price', text: 'Importe total', sort: true },
		//TODO: añadir nombre + apellidos + email CLIENTE
	];

	useEffect(() => {
		getAppraisals()
			.then((res) => {
				getProducts()
					.then((resProd) => {
						if (res.status === 200) {
							getRetailers()
								.then((response) => {
									getUsers()
										.then((resusers) => {
											setData(
												getData(
													res.data,
													resProd.data,
													response.data,
													resusers.data
												)
											);
											setRetailers(response.data);
											setUsersRetailers(
												getRetailersData(
													resusers.data.filter(
														(item) =>
															item.active && item.role === 'retailer-user'
													),
													response.data
												)
											);
											setRefresh(false);
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													'No se han podido obtener los usuarios de retailers de la base de datos.',
											});
										});
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se han podido obtener los retailers de la base de datos.',
									});
								});
						}
					})
					.catch((error) => {
						alertError({
							error: error,
							customMessage:
								'No se han podido obtener los productos de la base de datos.',
						});
					});
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los quotes de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Informe de piezas'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => setOpenExportDialog(true)}>
							Export excel
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
			<ExportFiltersDialog
				open={openExportDialog}
				setOpen={setOpenExportDialog}
				title='Exportar informe de piezas'
				retailers={retailers}
				usersRetailers={usersRetailers}
				data={data}
				onConfirm={(excelData) => exportExcel('PiecesReport', excelData)}
			/>
		</>
	);
}
