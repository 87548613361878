import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all strap materials
export const getStrapMaterials = () => {
    return authClient().get(`${API}/strap-materials`)
}

// Get strap material by id
export const getStrapMaterialById = (id) => {
    return authClient().get(`${API}/strap-materials/${id}`)
}

// Update strap material
export const updateStrapMaterial = async (id, strapMaterial) => {
    return authClient().put(`${API}/strap-materials/${id}`, strapMaterial)
}

// Save strap material
export const postStrapMaterial = async (strapMaterial) => {
    return authClient().post(`${API}/strap-materials`, strapMaterial)
}

// Change status strap material
export const changeStatusStrapMaterial = async (id, status) => {
    return authClient().put(`${API}/strap-materials/change-status/${id}?status=${status}`)
}

// Delete strap material
export const deleteStrapMaterial = (id) => {
    return authClient().delete(`${API}/strap-materials/${id}`)
}

