import React, { useState } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PreviewDialog from '../dialogs/PreviewDialog'
import { forceDownload, filenameFromURL } from '../../../utils/files'
import { SERVER_URL } from '../../../utils/index'
import { previewableImageTypes, previewableVideoTypes } from '../helpers/previewHelpers'
import { buttonsStyle } from '../tables/table'

const acceptImage = previewableImageTypes
const acceptVideo = previewableVideoTypes

function UploadMultipleButtonGroup(
	{
		element = 'file',
		fileURLs = [],
		setFileURLs,
		selectedFiles = [],
		setSelectedFiles,
		deletedURLs = [],
		setDeletedURLs,
		isImage,
		isVideo,
		previewButton,
		downloadButton,
		renderChildren,
		required,
		disabled
	} = {}
) {
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false)

	const acceptType = `${isImage ? acceptImage : ''} ${isVideo ? acceptVideo : ''}`

	const handleChange = event => {
		const tmp_files = selectedFiles
		Array.from(event.target.files).forEach(file => {
			tmp_files.push(file)
		})

		setSelectedFiles([...tmp_files])
	}

	const removeFile = type => file => {

		if (type === 'file') { // Just filter it (not uploaded yet)
			const tmp_selectedFiles = selectedFiles.filter(selFile => selFile !== file)
			setSelectedFiles([...tmp_selectedFiles])
		}
		else if (type === 'url') {// Filter url array and delete and add it to delete array
			const tmp_fileURLs = fileURLs.filter(url => url !== file)
			setFileURLs([...tmp_fileURLs])
			const tmp_deletedURLs = deletedURLs.concat(file)
			setDeletedURLs([...tmp_deletedURLs])
		}
		else
			console.log('invalid type provided')

	}

	const defaultRenderChildren = props => {
		const {
			file,
			url,
			index,
			openPreviewDialog,
			setOpenPreviewDialog,
			removeFile,
			previewButton,
			downloadButton
		} = props

		if (!file && !url)
			return <div></div>
			
		return (
			<div key = {index} className='mt-3'>
				<span>
					{file?.name || filenameFromURL(url)}
				</span>
      			{previewButton &&
					<>
						<Tooltip title={`Ver ${element}`}>
							<Button
								style={buttonsStyle}
								onClick={() => setOpenPreviewDialog(index)}
							>
								<VisibilityIcon/>
							</Button>
						</Tooltip>
						<PreviewDialog
							title={`Ver ${element}`}
							open={openPreviewDialog === index}
							setOpen={setOpenPreviewDialog}
						>
							<img
								className='img-fluid'
								src={ file
									? URL.createObjectURL(file)
									: `${SERVER_URL}/${url}`
								}
								alt = { file
									? file.name
									: undefined
								}
							/>
						</PreviewDialog>
					</>
				}
				{downloadButton &&
					<Tooltip title={`Descargar ${element}`}>
						<Button
							style={buttonsStyle}
							color='primary'
							onClick={() => {
								url
									? forceDownload(`${SERVER_URL}/${url}`, filenameFromURL(url))
									: forceDownload(URL.createObjectURL(file), file.name)
							}}
						>
							<DownloadIcon/>
						</Button>
					</Tooltip>
				}
				{!disabled && 
					<Tooltip title={`Eliminar ${element}`}>
						<Button style={buttonsStyle} onClick = {() => removeFile(file || url)}><DeleteIcon/></Button>
					</Tooltip>
				}
			</div>
		)
	}

	return (
		<>
			{!disabled && <label htmlFor="upload-files" className='mt-3'>
				<input
					style={{ display: 'none' }}
					id="upload-files"
					name="upload-files"
					type="file"
					multiple={true}
					accept={acceptType}
					onChange={handleChange}
				/>
				<Button
					style={{ marginRight: '15px' }}
					color="secondary"
					component="span"
					variant="outlined"
				>
					{( fileURLs?.length > 0 || selectedFiles?.length > 0 )
						? `Añadir ${element}`
						: `Subir ${element} ${required ? '*' : ''}`
					}
				</Button>
			</label>}

			{/* start - map existing urls */}
			{fileURLs?.map((url, index) => {
				const props = {
					url,
					index,
					openPreviewDialog,
					setOpenPreviewDialog,
					removeFile: removeFile('url'),
					previewButton,
					downloadButton
				}

				return renderChildren
					? renderChildren(props)
					: defaultRenderChildren(props)

			})}
			{/* end - map existing urls */}

			{/* start - map selected files */}
			{selectedFiles?.map((file, i) => {
				const index = i + (fileURLs?.length || 0)
				const props = {
					file,
					index,
					openPreviewDialog,
					setOpenPreviewDialog,
					removeFile: removeFile('file'),
					previewButton,
					downloadButton
				}

				return renderChildren
					? renderChildren(props)
					: defaultRenderChildren(props)

			})}
			{/* end - map selected files */}
		</>
	)
}
export default UploadMultipleButtonGroup