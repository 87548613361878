import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));

const ExportFiltersDialog = (props) => {
	const {
		title,
		open,
		setOpen,
		onConfirm,
		retailers,
		usersRetailers,
		data,
	} = props;
	const [filters, setFilters] = useState({});

	const classes = useStyles();

	const handleChangeFilters = (elem, value) => {
		setFilters({ ...filters, [elem]: value });
	};

	function filterDataExcel() {
		const excelData = data.filter((item) => {
			const newDate = new Date(item.createdAt);
			let filter = true;
			if (filters.date_from && filters.date_from !== '') {
				const fromDate = new Date(filters.date_from.replaceAll('-', '/'));
				filter = filter && newDate >= fromDate;
			}
			if (filters.date_to && filters.date_to !== '') {
				const toDate = new Date(filters.date_to.replaceAll('-', '/'));
				toDate.setHours(23, 59, 59);
				filter = filter && newDate <= toDate;
			}
			if (filters.retailer)
				filter = filter && item.retailer_id === filters.retailer;
			if (filters.user_retailer)
				filter = filter && item.user_retailer_id === filters.user_retailer;
			return filter;
		});
		onConfirm(excelData);
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth='md'
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='confirm-dialog'>
			<DialogTitle id='confirm-dialog'>{title}</DialogTitle>
			<DialogContent>
				<div className='container mb-5'>
					<div className='row'>
						<div className={classes.container}>
							<TextField
								id='date'
								label='Fecha desde'
								type='date'
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								value={filters.date_from}
								onChange={(event) =>
									handleChangeFilters('date_from', event?.target?.value)
								}
							/>
						</div>
						<div className={classes.container}>
							<TextField
								id='date'
								label='Fecha hasta'
								type='date'
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								value={filters.date_to}
								onChange={(event) =>
									handleChangeFilters('date_to', event?.target?.value)
								}
							/>
						</div>
					</div>
					<br />
					<FormControl style={{ width: '100%' }}>
						<InputLabel id='demo-simple-select-standard-label'>
							Retailer
						</InputLabel>
						<Select
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={filters.retailer}
							onChange={(event) =>
								handleChangeFilters('retailer', event?.target?.value)
							}
							MenuProps={MenuProps}>
							{retailers.map((option) => (
								<MenuItem key={option._id} value={option._id}>
									{option.name} {option.surname}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<br />
					<br />
					<FormControl style={{ width: '100%' }}>
						<InputLabel id='demo-simple-select-standard-label'>
							Vendedor
						</InputLabel>
						<Select
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={filters.user_retailer}
							onChange={(event) =>
								handleChangeFilters('user_retailer', event?.target?.value)
							}
							MenuProps={MenuProps}>
							{usersRetailers.map((option) => (
								<MenuItem key={option._id} value={option._id}>
									{option.name} {option.surname} - {option.retailer}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant='outlined'
					color='primary'
					onClick={() => {
						setOpen(false);
						filterDataExcel();
					}}>
					Exportar
				</Button>
				<Button
					variant='outlined'
					color='secondary'
					onClick={() => {
						setOpen(false);
					}}>
					Cancelar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ExportFiltersDialog;
