import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../../components/tables/table';
import {
	getProductTypes,
	deleteProductType,
	changeStatusProductType,
} from '../../../../../api/productTypes';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import LoopIcon from '@material-ui/icons/Loop';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import { Tooltip } from '@material-ui/core';

function getData(productTypes) {
	let data = [];
	for (let i = 0; i < productTypes.length; ++i) {
		const elem = {};
		elem.name = productTypes[i].name?.es;
		elem.active = productTypes[i].active;
		elem.id = productTypes[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function ProductTypesPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [productTypeId, setProductTypeId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [disabledProductType, setDisabledProductType] = useState(false);
	const [refresh, setRefresh] = useState(false);

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setProductTypeId(cell);
							setDisabledProductType(disabled);
							setOpenConfirmDialog(1);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() =>
							history.push('/auxiliares/editar-tipo-producto/' + cell)
						}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setProductTypeId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Tipo de producto', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getProductTypes()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los tipos de producto de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de tipos de producto'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/auxiliares/editar-tipo-producto')}>
							Añadir tipo de producto
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledProductType ? 'activar' : 'desactivar') +
							' este tipo de producto?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusProductType(productTypeId, disabledProductType)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledProductType ? 'Activado!' : 'Desactivado!',
											customMessage:
												'Tipo de producto ' +
												(disabledProductType ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledProductType ? 'activar' : 'desactivar') +
											' el tipo de producto.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							'Estás seguro de que quieres eliminar este tipo de producto?'
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteProductType(productTypeId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage:
												'Tipo de producto eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido eliminar el tipo de producto.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
