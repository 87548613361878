import React, {useEffect, useState} from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { Button, TextField } from "@material-ui/core"
import { getTextAboutWatchupgrade, saveTextAboutWatchupgrade } from '../../../../api/about';
import { alertError, alertSuccess } from '../../../../utils/logger';

export default function EditAboutPage() {
    const [edit, setEdit] = useState(false)
    const [text, setText] = useState('')
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        getTextAboutWatchupgrade().then((res) => {
            if (res.status === 200) {
                setText(res.data.text)
            } 
        }).catch(error => {
            alertError({ error: error, customMessage: 'No se ha podido obtener el texto de la base de datos.' })
        }).finally(() => {
            setRefresh(false)
            setEdit(false)
        })
    }, [refresh])

    const handleChange = (event) => {
        setText(event.target.value)
    }

    const saveText = () => {
        saveTextAboutWatchupgrade({ text }).then((res) => {
            if (res.status === 200) {
                alertSuccess({ title: 'Guardado!', customMessage: 'Los cambios realizados se han guardado correctamente.' })
                setEdit(false)
            } 
        }).catch(error => {
            alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
        })
    }

    return (
        <>
          <Card>
            <CardHeader title='About Watchupgrade'>
              <CardHeaderToolbar>
                {!edit && <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setEdit(true)}
                >
                  Editar
                </button>}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <TextField
                    id={`text`}
                    label="Texto"
                    value={text}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                    disabled={!edit}
                    multiline={true}
                    rows={5}
                    margin="normal"
                    variant="outlined"
                />
            </CardBody>
          </Card>
          {edit && <>
            <Button
                onClick={() => saveText()}
                variant="outlined"
                style={{ marginRight: '20px' }}
                color="primary">
                Guardar texto
            </Button>
            <Button
                onClick={() => {
                    setRefresh(true)
                }}
                variant="outlined">
                Cancelar
            </Button>
          </>}
        </>
      );
}