import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../../components/tables/table';
import {
	getBoxMaterials,
	deleteBoxMaterial,
	changeStatusBoxMaterial,
} from '../../../../../api/boxMaterials';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import LoopIcon from '@material-ui/icons/Loop';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import { Tooltip } from '@material-ui/core';

function getData(boxMaterials) {
	let data = [];
	for (let i = 0; i < boxMaterials.length; ++i) {
		const elem = {};
		elem.name = boxMaterials[i].name?.es;
		elem.active = boxMaterials[i].active;
		elem.id = boxMaterials[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function BoxMaterialPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [boxMaterialId, setBoxMaterialId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [disabledBoxMaterial, setDisabledBoxMaterial] = useState(false);
	const [refresh, setRefresh] = useState(false);

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setBoxMaterialId(cell);
							setDisabledBoxMaterial(disabled);
							setOpenConfirmDialog(1);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() =>
							history.push('/auxiliares/editar-material-caja/' + cell)
						}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setBoxMaterialId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Material de la caja', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getBoxMaterials()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los materiales de la caja de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de materiales de la caja'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/auxiliares/editar-material-caja')}>
							Añadir material de la caja
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledBoxMaterial ? 'activar' : 'desactivar') +
							' este material de la caja?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusBoxMaterial(boxMaterialId, disabledBoxMaterial)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledBoxMaterial ? 'Activado!' : 'Desactivado!',
											customMessage:
												'Material de la caja ' +
												(disabledBoxMaterial ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledBoxMaterial ? 'activar' : 'desactivar') +
											' el material de la caja.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							'Estás seguro de que quieres eliminar este material de la caja?'
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteBoxMaterial(boxMaterialId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage:
												'Material de la caja eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido eliminar el material de la caja.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
