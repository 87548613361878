import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import Modal from 'react-modal';
import QRCode from 'qrcode.react';

import { getRetailers, changeStatusRetailer } from '../../../../api/retailers';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import LoopIcon from '@material-ui/icons/Loop';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { WEBAPP_URL } from '../../../../utils/index';

function getData(retailers) {
	let data = [];
	for (let i = 0; i < retailers.length; ++i) {
		const elem = {};
		elem.name = retailers[i].name;
		elem.cif = retailers[i].cif;
		elem.active = retailers[i].active;
		elem.id = retailers[i]._id;
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

Modal.setAppElement(document.getElementById('root'));

export default function RetailersPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [retailerId, setRetailerId] = useState(null);
	const [retailerURL, setRetailerURL] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [disabledRetailer, setDisabledRetailer] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);

	const handleClipboard = () => {
		navigator.clipboard.writeText(retailerURL);
	};

	const handleDownload = () => {
		const canvas = document.getElementById('qr-gen');
		const pngUrl = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream');
		let downloadLink = document.createElement('a');
		downloadLink.href = pngUrl;
		downloadLink.download = 'retailer-qr-code.png';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/editar-retailer/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setRetailerId(cell);
							setDisabledRetailer(disabled);
							setOpenConfirmDialog(true);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title={'QR'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setRetailerURL(`${WEBAPP_URL}?retailer=${cell}`);
							setIsOpen(true);
						}}>
						QR
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Retailer', sort: true },
		{ dataField: 'cif', text: 'CIF/NIF', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getRetailers()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los retailers de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de retailers'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/editar-retailer')}>
							Añadir Retailer
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledRetailer ? 'activar' : 'desactivar') +
							' este retailer?'
						}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusRetailer(retailerId, disabledRetailer)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledRetailer ? 'Activado!' : 'Desactivado!',
											customMessage:
												'Retailer ' +
												(disabledRetailer ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledRetailer ? 'activar' : 'desactivar') +
											' el retailer.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				style={{
					overlay: {
						zIndex: '1050',
						backgroundColor: 'rgba(255, 255, 255, 0.75)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: '50%',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						textAlign: 'center',
						padding: '2rem',
					},
				}}>
				<div className='scroll-component'>
					<h2>Retailer QR</h2>
					<div className='margin-1'>
						<button
							type='button'
							className='btn btn-secondary'
							onClick={handleDownload}>
							Descargar
						</button>
					</div>
					<QRCode id='qr-gen' size={360} value={retailerURL} />
					<div className='margin-1'>
						<button
							type='button'
							className='btn btn-secondary'
							onClick={handleClipboard}>
							Copiar URL
						</button>
					</div>
					<button
						type='button'
						className='btn btn-primary margin-top-2'
						onClick={() => setIsOpen(false)}>
						Cerrar
					</button>
				</div>
			</Modal>
		</>
	);
}
