import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';

const { SearchBar, ClearSearchButton } = Search;

export const buttonsStyle = {
	maxWidth: '30px',
	maxHeight: '30px',
	minWidth: '30px',
	minHeight: '30px',
	marginLeft: '1em',
};

export const buttonsStyleB = {
	maxHeight: '30px',
	minWidth: '30px',
	minHeight: '30px',
	marginLeft: '1em',
	width: '100px',
};

export function booleanFormatter(cell) {
	return cell ? (
		<CheckBox style={{ color: '#b39743' }} />
	) : (
		<CheckBoxOutlineBlank style={{ color: '#b39743' }} />
	);
}

const buildDate = (date) => {
	if (Object.prototype.toString.call(date) !== '[object Date]') return;
	const displayDate = `${date.getDate()}/${date.getMonth() +
		1}/${date.getFullYear()}  ${date.getHours()}:${String(
		date.getMinutes()
	).padStart(2, '0')}`;
	return displayDate;
};

function changeTimeZone(date, locale, timeZone) {
	return new Date(
		date.toLocaleString(locale, {
			timeZone: timeZone,
		})
	);
}

export function dateFormatter(cell) {
	if (!cell) return '';

	let date = changeTimeZone(new Date(cell), 'en-ES', 'Europe/Madrid');
	return buildDate(date);
}

const customTotal = (from, to, size) => (
	<span
		className='react-bootstrap-table-pagination-total'
		style={{ paddingLeft: '5px' }}>
		Mostrando {from}-{to} de {size} resultados
	</span>
);

const pagination = paginationFactory({
	page: 1,
	sizePerPage: 10,
	lastPageText: '>>',
	firstPageText: '<<',
	nextPageText: '>',
	prePageText: '<',
	showTotal: true,
	paginationTotalRenderer: customTotal,
	alwaysShowAllBtns: false,
	onPageChange: function(page, sizePerPage) {
		// console.log('page', page)
		// console.log('sizePerPage', sizePerPage)
	},
	onSizePerPageChange: function(page, sizePerPage) {
		// console.log('page', page)
		// console.log('sizePerPage', sizePerPage)
	},
});

export default function Table({ data, columns, ...tableProps }) {
	return (
		<ToolkitProvider
			bootstrap4
			keyField='id'
			data={data}
			columns={columns}
			search>
			{(props) => (
				<div>
					<SearchBar placeholder='Buscar' {...props.searchProps} />
					<ClearSearchButton
						text='Borrar'
						className='ml-3'
						{...props.searchProps}
					/>
					<br />
					<br />
					<BootstrapTable
						wrapperClasses='table-responsive'
						classes='table table-head-custom table-vertical-center overflow-hidden'
						bordered={false}
						def
						// remote
						pagination={pagination}
						striped
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}
