import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	dateFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import { deleteAppraisal, getAppraisals } from '../../../../api/appraisals';
import { getRetailers } from '../../../../api/retailers';
import { getUsers } from '../../../../api/users';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { CurrencySymbolMap } from '../../../../constants';

export const APPRAISAL_STATUS = [
	{ id: 'pending', label: 'Pending' },
	{ id: 'expired', label: 'Expired' },
	{ id: 'accepted', label: 'Approved' },
	{ id: 'partiallyAccepted', label: 'Partially approved' },
	{ id: 'noOffer', label: 'No Offer' },
	{ id: 'rejected', label: 'Refused' },
];

function getData(appraisals, retailers, userretailers) {
	let data = [];
	for (let i = 0; i < appraisals.length; ++i) {
		const elem = {};
		elem.createdAt = appraisals[i].createdAt;
		elem.code = appraisals[i].code;
		elem.priceValue =
			appraisals[i].products.reduce(
				(total, currentValue) => (total = total + currentValue.price),
				0
			) || 0;
		const cli = userretailers.find((item) => item._id === appraisals[i].client);
		elem.client = `${cli?.name} ${cli?.surname}` || '----';
		const usr = userretailers.find(
			(item) => item._id === appraisals[i].retailerUser
		);
		elem.userretailer = `${usr?.name} ${usr?.surname}` || '----';
		const retailer = retailers.find(
			(item) =>
				item._id ===
				userretailers.find((item) => item._id === appraisals[i].retailerUser)
					?.retailer
		);
		elem.retailer = retailer?.name || '----';
		elem.status =
			(appraisals[i].status &&
				APPRAISAL_STATUS.find((item) => item.id === appraisals[i].status)
					?.label) ||
			appraisals[i].status;
		elem.id = appraisals[i]._id;
		let currencyCode = CurrencySymbolMap[retailer?.currency] || '€';
		elem.price = elem.priceValue + currencyCode;
		elem.offerSent = appraisals[i].offerSent;

		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

export default function AppraisalsPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [appraisalId, setAppraisalId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/editar-tasacion/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setAppraisalId(cell);
							setOpenConfirmDialog(true);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: 'createdAt',
			text: 'Fecha alta',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'code', text: 'Código', sort: true },
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{ dataField: 'userretailer', text: 'Gestionado por', sort: true },
		{ dataField: 'client', text: 'Vendedor', sort: true },
		{ dataField: 'price', text: 'Precio', sort: true },
		{ dataField: 'status', text: 'Estado', sort: true },
		{
			dataField: 'offerSent',
			text: 'Oferta Enviada',
			headerAlign: 'center',
			align: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getAppraisals()
			.then((res) => {
				if (res.status === 200) {
					getRetailers()
						.then((response) => {
							getUsers()
								.then((resusers) => {
									if (res.status === 200) {
										setData(getData(res.data, response.data, resusers.data));
										setRefresh(false);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se han podido obtener los usuarios de retailers de la base de datos.',
									});
								});
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener los retailers de la base de datos.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener las tasaciones de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de tasaciones'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/editar-tasacion')}>
							Añadir tasación
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar esta tasación?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteAppraisal(appraisalId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrada!',
											customMessage: 'Tasación eliminada correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar la tasación.',
									});
								});
						}}>
						<div>
							{
								'También se eliminarán los productos y citas asociados a esta tasación'
							}
						</div>
					</ConfirmDialog>
				</CardBody>
			</Card>
		</>
	);
}
