import React, { useEffect, useState } from 'react';
import Table, {
	dateFormatter,
	buttonsStyleB,
} from '../../../../components/tables/table';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import { getCalls, deleteCall } from '../../../../../api/calls';
import { getRetailers } from '../../../../../api/retailers';
import { getUsers } from '../../../../../api/users';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Tooltip } from '@material-ui/core';
import xlsx from 'json-as-xlsx';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import ExportFiltersDialog from '../../../../components/dialogs/ExportFiltersDialog';
import { getRetailersData } from '../../appraisals/EditAppraisalsPage';

function getData(calls, retailers, userretailers) {
	let data = [];
	for (let i = 0; i < calls.length; ++i) {
		const elem = {};
		elem.date = calls[i].date;
		elem.id = calls[i]._id;
		elem.answeredBy =
			userretailers.find((item) => item._id === calls[i].answeredBy)?.name ||
			null;
		elem.client =
			userretailers.find((item) => item._id === calls[i].client)?.name ||
			'----';
		elem.email =
			userretailers.find((item) => item._id === calls[i].client)?.email ||
			'----';
		elem.retailer =
			retailers.find((item) => item._id === calls[i].retailer)?.name || '----';
		elem.retailer_id =
			retailers.find((item) => item._id === calls[i].retailer)?._id || '----';
		elem.user_retailer_id =
			userretailers.find((item) => item._id === calls[i].client)?._id || '----';
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

function exportExcel(fileName, rows) {
	const content = [];
	rows.forEach((row) => {
		content.push({ ...row, createdAt: dateFormatter(row.createdAt) });
	});

	let xlsxFile = [
		{
			sheet: 'Calls_report',
			columns: [
				{ label: 'FECHA LLAMADA', value: 'date' },
				{ label: 'VENDEDOR', value: 'client' },
				{ label: 'EMAIL VENDEDOR', value: 'email' },
				{ label: 'RETAILER', value: 'retailer' },
				{ label: 'ATENDIDA POR', value: 'answeredBy' },
			],
			content,
		},
	];
	const settings = {
		fileName,
		extraLength: 10,
	};
	xlsx(xlsxFile, settings);
}

export default function CallsReportsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [openExportDialog, setOpenExportDialog] = useState(false);
	const [retailers, setRetailers] = useState([]);
	const [usersRetailers, setUsersRetailers] = useState([]);
	const [callId, setCallId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	function callFormatter(cell) {
		return cell || <CallMissedIcon />;
	}

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyleB}
						size='small'
						onClick={() => {
							setCallId(cell);
							setOpenConfirmDialog(true);
						}}>
						<div className='d-flex flex-row'>
							{'Eliminar'}
							<DeleteIcon />
						</div>
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: 'date',
			text: 'Fecha',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'client', text: 'Vendedor', sort: true },
		{ dataField: 'email', text: 'Email Vendedor', sort: true },
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{
			dataField: 'answeredBy',
			text: 'Atendida por',
			sort: true,
			formatter: callFormatter,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getCalls()
			.then((res) => {
				if (res.status === 200) {
					getRetailers()
						.then((response) => {
							getUsers()
								.then((resusers) => {
									if (res.status === 200) {
										setData(getData(res.data, response.data, resusers.data));
										setRetailers(response.data);
										setUsersRetailers(
											getRetailersData(
												resusers.data.filter(
													(item) => item.active && item.role === 'retailer-user'
												),
												response.data
											)
										);
										setRefresh(false);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se han podido obtener los usuarios de retailers de la base de datos.',
									});
								});
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener los retailers de la base de datos.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener las llamadas de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Informe de llamadas'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => setOpenExportDialog(true)}>
							Export excel
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar esta llamada?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteCall(callId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrada!',
											customMessage: 'Llamada eliminada correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar la llamada.',
									});
								});
						}}></ConfirmDialog>
				</CardBody>
			</Card>
			<ExportFiltersDialog
				open={openExportDialog}
				setOpen={setOpenExportDialog}
				title='Exportar informe de llamadas'
				retailers={retailers}
				usersRetailers={usersRetailers}
				data={data}
				onConfirm={(excelData) => exportExcel('CallsReport', excelData)}
			/>
		</>
	);
}
