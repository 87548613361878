import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../../components/tables/table';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import {
	getUsers,
	changeStatusUser,
	deleteUser,
} from '../../../../../api/users';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { getRetailers } from '../../../../../api/retailers';
import { alertError, alertSuccess } from '../../../../../utils/logger';

function getData(users, retailers) {
	let data = [];
	for (let i = 0; i < users.length; ++i) {
		if (users[i].role === 'retailer-user') {
			const elem = {};
			elem.name = users[i].name;
			elem.surname = users[i].surname;
			elem.phone = users[i].phone;
			elem.email = users[i].email;
			elem.commission = users[i].commission + '%';
			elem.retailer =
				retailers.find((item) => item._id === users[i].retailer)?.name ||
				'----';
			elem.active = users[i].active;
			elem.id = users[i]._id;
			data = data.concat(elem);
		}
	}
	data.reverse();
	return data;
}

export default function RetailerUsersPage() {
	const [data, setData] = useState([]);
	const [userId, setUserId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [disabledRetailerUser, setDisabledRetailerUser] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setUserId(cell);
							setDisabledRetailerUser(disabled);
							setOpenConfirmDialog(1);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() =>
							history.push('/usuarios/editar-usuario-retailer/' + cell)
						}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setUserId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'surname', text: 'Apellidos', sort: true },
		{ dataField: 'phone', text: 'Teléfono', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{ dataField: 'commission', text: 'Comisión', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getRetailers()
			.then((response) => {
				getUsers()
					.then((res) => {
						if (res.status === 200) {
							setData(getData(res.data, response.data));
							setRefresh(false);
						}
					})
					.catch((error) => {
						alertError({
							error: error,
							customMessage:
								'No se han podido obtener los usuarios de retailers de la base de datos.',
						});
					});
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los retailers de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de usuarios de retailers'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/usuarios/editar-usuario-retailer')}>
							Añadir usuario de retailer
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledRetailerUser ? 'activar' : 'desactivar') +
							' este usuario de retailer?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusUser(userId, disabledRetailerUser)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledRetailerUser
												? 'Activado!'
												: 'Desactivado!',
											customMessage:
												'Usuario de retailer ' +
												(disabledRetailerUser ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledRetailerUser ? 'activar' : 'desactivar') +
											' el usuario de retailer.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							'Estás seguro de que quieres eliminar a este usuario de retailer?'
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(userId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage:
												'Usuario de retailer eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido eliminar el usuario de retailer.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
