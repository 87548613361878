import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all appraisals
export const getAppraisals = () => {
    return authClient().get(`${API}/appraisals`)
}

// Get appraisal by id
export const getAppraisalById = (id) => {
    return authClient().get(`${API}/appraisals/${id}`)
}

// Update appraisal
export const updateAppraisal = async (id, appraisal) => {
    return authClient().put(`${API}/appraisals/${id}`, appraisal)
}

// Save appraisal
export const postAppraisal = async (appraisal) => {
    return authClient().post(`${API}/appraisals`, appraisal)
}

// Delete appraisal
export const deleteAppraisal = (id) => {
    return authClient().delete(`${API}/appraisals/${id}`)
}

