import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { getUserById, postUser, updateUser } from '../../../../../api/users';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import { getRetailers } from '../../../../../api/retailers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getEmptyUserUserRetailer() {
	return {
		name: '',
		surname: '',
		phone: '',
		email: '',
		retailer: '',
		role: 'retailer-user',
		active: true,
		password: '',
		repeatPassword: '',
		commission: '',
	};
}

export default function EditUserRetailersPage() {
	const [userRetailer, setUserRetailer] = useState(getEmptyUserUserRetailer());
	const [retailers, setRetailers] = useState([]);
	const userRetailerId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getRetailers()
			.then((res) => {
				if (res.status === 200) {
					setRetailers(res.data);
					if (!userRetailerId) {
						disableLoadingData();
						return;
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los retailers.',
				});
				history.push('/usuarios/usuarios-retailer');
			});
		if (userRetailerId) {
			getUserById(userRetailerId)
				.then((res) => {
					if (res.status === 200) {
						const user = res.data;
						delete user.password;
						setUserRetailer(user);
						disableLoadingData();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido obtener el usuario de retailer.',
					});
					history.push('/usuarios/usuarios-retailer');
				});
		}
	}, [userRetailerId, disableLoadingData, history]);

	function saveUserRetailer() {
		if (userRetailer.password !== userRetailer.repeatPassword) {
			alertError({
				error: null,
				customMessage: 'Las contraseñas no coinciden.',
			});
			return;
		}
		delete userRetailer.repeatPassword;
		if (!userRetailerId) {
			postUser(userRetailer)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardado!',
							customMessage:
								'El usuario de retailer se ha creado correctamente.',
						});
						history.push('/usuarios/usuarios-retailers');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar el userUserRetailer.',
					});
				});
		} else {
			updateUser(userRetailerId, userRetailer)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Guardado!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						});
						history.push('/usuarios/usuarios-retailer');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setUserRetailer({ ...userRetailer, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos del usuario de retailer'></CardHeader>
					<CardBody>
						<TextField
							id={`name`}
							label='Nombre'
							value={userRetailer.name}
							onChange={handleChange('name')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`surname`}
							label='Apellidos'
							value={userRetailer.surname}
							onChange={handleChange('surname')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`phone`}
							label='Teléfono'
							value={userRetailer.phone}
							onChange={handleChange('phone')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`email`}
							label='Email'
							value={userRetailer.email}
							onChange={handleChange('email')}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete='new-password'
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`commission`}
							label='Comisión'
							value={userRetailer.commission}
							onChange={handleChange('commission')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Retailer
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={userRetailer.retailer}
								onChange={handleChange('retailer')}
								MenuProps={MenuProps}>
								{retailers.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`password`}
							label='Contraseña'
							value={userRetailer.password}
							onChange={handleChange('password')}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete='new-password'
							type='password'
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`repeatPassword`}
							label='Repetir contraseña'
							value={userRetailer.repeatPassword}
							onChange={handleChange('repeatPassword')}
							InputLabelProps={{
								shrink: true,
							}}
							type='password'
							margin='normal'
							variant='outlined'
							required
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={userRetailer.active}
									onChange={() =>
										setUserRetailer({
											...userRetailer,
											active: !userRetailer.active,
										})
									}
									name='checkActive'
								/>
							}
							label='Activo'
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/usuarios/usuarios-retailers')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveUserRetailer()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar usuario de retailer
				</Button>
			</>
		);
}
