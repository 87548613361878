import {/* baseClient, */ authClient} from '../../../../utils/index'

// TO REMOVE with ./authRedux & ./authInit
// API auth calls in api folder

export const LOGIN_URL = `http://localhost:4000/api/user/signin` //`${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = `http://localhost:4000/api/user/signup`;
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export function login(email, password) {
  return authClient.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return authClient.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return authClient.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return authClient.get(ME_URL);
}
