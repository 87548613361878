import { /*baseClient,*/ API, authClient } from '../../utils/index'

export const login = (email, password) => {
  const URL = `${API}/user/signin`
  const body = { email, password }
  return authClient().post(URL, body)
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  console.log('refreshTokens api call')

  const URL = `${API}/user/refresh`
  return authClient().get(URL)
}