import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { useSkeleton } from "../../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../../utils/logger"
import { getCountryById, postCountry, updateCountry } from '../../../../../api/countries'


function getEmptyCountry() {
  return {
    name: '',
    active: true
  }
}

export default function EditCountriesPage() {
  const [country, setCountry] = useState(getEmptyCountry())
  const countryId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!countryId) {
      disableLoadingData()
      return
    }
    getCountryById(countryId).then(res => {
      if (res.status === 200) {
        setCountry(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener el país.' })
      history.push('/auxiliares/paises')
    })
  }, [countryId, disableLoadingData, history])

  function saveCountry() {
    if (!countryId) {
      postCountry(country).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Guardado!', customMessage: 'El país se ha creado correctamente.' })
          history.push('/auxiliares/paises')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar el país.' })
      })
    } else {
      updateCountry(countryId, country).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Guardado!', customMessage: 'Los cambios realizados se han guardado correctamente.' })
          history.push('/auxiliares/paises')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setCountry({ ...country, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Datos del país'>
        </CardHeader>
        <CardBody>
        <TextField
            id={`name`}
            label="País"
            value={country.name}
            onChange={handleChange('name')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <br />
          <FormControlLabel
            control={<Checkbox checked={country.active} onChange={() => setCountry({ ...country, active: !country.active })} name="checkActive" />}
            label="Activo"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/auxiliares/paises')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveCountry()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar país
      </Button>
    </>
  );
}

