import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../../components/tables/table';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import {
	getUsers,
	changeStatusUser,
	deleteUser,
} from '../../../../../api/users';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { alertError, alertSuccess } from '../../../../../utils/logger';

function getData(users) {
	let data = [];
	for (let i = 0; i < users.length; ++i) {
		if (users[i].role === 'admin') {
			const elem = {};
			elem.name = users[i].name;
			elem.surname = users[i].surname;
			elem.email = users[i].email;
			elem.active = users[i].active;
			elem.id = users[i]._id;
			data = data.concat(elem);
		}
	}
	data.reverse();
	return data;
}

export default function AdministratorsPage() {
	const [data, setData] = useState([]);
	const [userId, setUserId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [disabledAdmin, setDisabledAdmin] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setUserId(cell);
							setDisabledAdmin(disabled);
							setOpenConfirmDialog(1);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() =>
							history.push('/usuarios/editar-administrador/' + cell)
						}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setUserId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'surname', text: 'Apellidos', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los administradores de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de administradores'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/usuarios/editar-administrador')}>
							Añadir administrador
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledAdmin ? 'activar' : 'desactivar') +
							' este administrador?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusUser(userId, disabledAdmin)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledAdmin ? 'Activado!' : 'Desactivado!',
											customMessage:
												'Administrador ' +
												(disabledAdmin ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledAdmin ? 'activar' : 'desactivar') +
											' el administrador.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar a este administrador?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(userId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage: 'Administrador eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar el administrador.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
