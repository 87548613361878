import axios from 'axios';

export const baseClient = () => {
	return axios.create();
};

export const authClient = () => {
	return axios.create({
		withCredentials: true,
	});
};

const PRO =
	process.env.NODE_ENV === 'production' || process.env.REACT_APP_API === 'pro';

export const SERVER_URL = PRO
	? 'https://api.watchupgrade.com:4004'
	: 'http://localhost:4001';

export const WEBAPP_URL = 'https://webapp.watchupgrade.com/';

export const API = `${SERVER_URL}/api`;
