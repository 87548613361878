import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { useSkeleton } from "../../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../../utils/logger"
import { getProductStatusById, postProductStatus, updateProductStatus } from '../../../../../api/productStatuses'
import MultilanguageTabBlock from '../../../../components/customFields/MultilanguageTabBlock'


function getEmptyProductStatus() {
  return {
    name: {},
    active: true
  }
}

export default function EditProductStatusesPage() {
  const [productStatus, setProductStatus] = useState(getEmptyProductStatus())
  const productStatusId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!productStatusId) {
      disableLoadingData()
      return
    }
    getProductStatusById(productStatusId).then(res => {
      if (res.status === 200) {
        setProductStatus(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener el estado de producto.' })
      history.push('/auxiliares/estado-producto')
    })
  }, [productStatusId, disableLoadingData, history])

  function saveProductStatus() {
    if (!productStatusId) {
      postProductStatus(productStatus).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Guardado!', customMessage: 'El estado de producto se ha creado correctamente.' })
          history.push('/auxiliares/estado-producto')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar el estado de producto.' })
      })
    } else {
      updateProductStatus(productStatusId, productStatus).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Guardado!', customMessage: 'Los cambios realizados se han guardado correctamente.' })
          history.push('/auxiliares/estado-producto')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    let newField = productStatus[element]
    newField[lang] = event.target.value
    setProductStatus({ ...productStatus, [element]: newField })
  }

  const renderMultilanguageTabContent = lang => {
		return (
			<div style = {{ paddingTop: '5px' }}>
        <TextField
            id={`name`}
            label="Estado de producto"
            value={(productStatus.name && productStatus.name[lang.key]) || ''}
            onChange={handleChange('name', lang.key)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />

			</div>
		)
	}

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Datos del estado de producto'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <br />
          <FormControlLabel
            control={<Checkbox checked={productStatus.active} onChange={() => setProductStatus({ ...productStatus, active: !productStatus.active })} name="checkActive" />}
            label="Activo"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/auxiliares/estado-producto')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveProductStatus()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar estado de producto
      </Button>
    </>
  );
}

