import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get text about watchupgrade
export const getTextAboutWatchupgrade = () => {
    return authClient().get(`${API}/about`)
}

// Save text about watchupgrade
export const saveTextAboutWatchupgrade = async (text) => {
    return authClient().post(`${API}/about`, text)
}