import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import { deleteProduct, getProducts } from '../../../../api/products';
import { getProductStatuses } from '../../../../api/productStatuses';
import { getProductTypes } from '../../../../api/productTypes';
import { getAppraisals } from '../../../../api/appraisals';
import { getRetailers } from '../../../../api/retailers';
import { getUsers } from '../../../../api/users';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { PRICE_STATUS } from './EditProductsPage';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { CurrencySymbolMap } from '../../../../constants';

function getData(products, types, status, appraisals, users, retailers) {
	let data = [];
	for (let i = 0; i < products.length; ++i) {
		const elem = {};
		const productStatus = status.find(
			(item) => item._id === products[i].status
		);
		const productType = types.find((item) => item._id === products[i].type);
		const appraisal = appraisals.find(
			(item) => item._id === products[i].appraisal
		);
		const retailer = retailers.find(
			(item) =>
				item._id ===
				users.find((item) => item._id === appraisal?.retailerUser)?.retailer
		);
		elem.createdAt = products[i].createdAt;
		elem.productCode = products[i].code;
		elem.appraisal = appraisal?.code;
		elem.retailer = retailer?.name || '----';
		elem.type = productType?.name?.es || productType?.name?.en || '----';
		elem.brand = products[i].brand;
		elem.reference = products[i].reference;
		let currencyCode = CurrencySymbolMap[retailer?.currency] || '€';
		elem.price = (products[i].price || 0) + currencyCode;
		elem.priceStatus =
			(products[i].priceStatus &&
				PRICE_STATUS.find((item) => item.id === products[i].priceStatus)
					?.label) ||
			products[i].priceStatus;
		elem.status = productStatus?.name?.es || productStatus?.name?.en || '----';
		elem.id = products[i]._id;
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

export default function ProductsPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [productId, setProductId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/editar-producto/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setProductId(cell);
							setOpenConfirmDialog(true);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: 'createdAt',
			text: 'Fecha alta (año-mes-día)',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'reference', text: 'Referencia', sort: true },
		{ dataField: 'appraisal', text: 'Tasación', sort: true },
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{ dataField: 'type', text: 'Tipo', sort: true },
		{ dataField: 'brand', text: 'Marca', sort: true },
		{ dataField: 'price', text: 'Precio', sort: true },
		{ dataField: 'status', text: 'Estado del reloj', sort: true },
		{ dataField: 'priceStatus', text: 'Estado', sort: true },
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getProductStatuses()
			.then((resp) => {
				if (resp.status === 200) {
					getProductTypes()
						.then((respTypes) => {
							if (respTypes.status === 200) {
								getAppraisals()
									.then((respAppraisals) => {
										if (respAppraisals.status === 200) {
											getUsers()
												.then((respUsers) => {
													if (respUsers.status === 200) {
														getRetailers()
															.then((respRetailers) => {
																if (respRetailers.status === 200) {
																	getProducts()
																		.then((res) => {
																			if (res.status === 200) {
																				setData(
																					getData(
																						res.data,
																						respTypes.data,
																						resp.data,
																						respAppraisals.data,
																						respUsers.data,
																						respRetailers.data
																					)
																				);
																				setRefresh(false);
																			}
																		})
																		.catch((error) => {
																			alertError({
																				error: error,
																				customMessage:
																					'No se han podido obtener los productos de la base de datos.',
																			});
																		});
																}
															})
															.catch((error) => {
																alertError({
																	error: error,
																	customMessage:
																		'No se han podido obtener los retailers de la base de datos.',
																});
															});
													}
												})
												.catch((error) => {
													alertError({
														error: error,
														customMessage:
															'No se han podido obtener los usuarios de la base de datos.',
													});
												});
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												'No se han podido obtener los tasaciones de la base de datos.',
										});
									});
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener los tipos de productos de la base de datos.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los estados de los productos de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de productos'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/editar-producto')}>
							Añadir producto
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar este producto?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteProduct(productId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage: 'Producto eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar el producto.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
