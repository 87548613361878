/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import socket from './socket';
import { useParams } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Box,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PRICE_STATUS } from '../../modules/ContentManager/products/EditProductsPage';
import { APPRAISAL_STATUS } from '../../modules/ContentManager/appraisals/AppraisalsPage';
import { getMovements } from '../../../api/movements';
import {
	getUserRetailerByEmail,
	getUserRetailerByRetailer,
} from '../../../api/users';
import { getRetailerById, getRetailerByName } from '../../../api/retailers';
import { getBoxMaterials } from '../../../api/boxMaterials';
import { getStrapMaterials } from '../../../api/strapMaterials';
import { getProductStatuses } from '../../../api/productStatuses';
import { alertError, alertSuccess } from '../../../utils/logger';
import { postAppraisal, updateAppraisal } from '../../../api/appraisals';
import { postProduct, updateProduct } from '../../../api/products';
import { postUser, updateUser } from '../../../api/users';
import ReplayIcon from '@material-ui/icons/Replay';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { updateCall, updateCallStatus } from '../../../api/calls';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { CurrencySymbolMap } from '../../../constants';
import {
	updateClientID,
	updateSessions,
} from '../../../redux/sessionsRedux/sessionsActions';
import PreviewDialog from '../dialogs/PreviewDialog';
import { forceDownload } from '../../../utils/files';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

const { RTCPeerConnection, RTCSessionDescription } = window;
let localStream = null;
let inCall = false;
const configuration = {
	iceServers: [
		{
			urls: 'stun:openrelay.metered.ca:80',
		},
		{
			urls: 'turn:api.watchupgrade.com:3478',
			username: 'watch',
			credential: 'watchupgrade',
		},
		{
			urls: 'turn:api.watchupgrade.com:3478?transport=tcp',
			username: 'watch',
			credential: 'watchupgrade',
		},
	],
};

const addICERetry = async (connection, ice, type = '') => {
	let attempts = 0;
	while (attempts < 10) {
		try {
			await connection.addIceCandidate(ice);
			console.log(`ICE OK ${type}:\n${ice?.candidate || ice}`);
			return true;
		} catch (e) {
			attempts++;
			if (attempts === 10) {
				return console.log(`ICE KO ${type}:\n${ice?.candidate || ice}`);
			}
			console.log('ICE FAIL. Retrying...');
			await new Promise((r) => setTimeout(r, 1000));
		}
	}
};

const CallClient = () => {
	const [clientData, setClientData] = useState({ role: 'seller' });
	const [appraisalData, setAppraisalData] = useState({ status: 'pending' });
	const [productsData, setProductsData] = useState([
		{
			priceStatus: 'pending',
			originalCase: false,
			warrantyCertificate: false,
			instructions: false,
			price: 0,
			commissionRetailer: 0,
			commissionUserRetailer: 0,
		},
	]);
	const [movements, setMovements] = useState([]);
	const [retailers, setRetailers] = useState([]);
	const [retailersOptions, setRetailersOptions] = useState([]);
	const [boxMaterials, setBoxMaterials] = useState([]);
	const [strapMaterials, setStrapMaterials] = useState([]);
	const [productStatuses, setProductStatuses] = useState([]);
	const [selectedImages, setSelectedImages] = useState({ 0: { 0: null } });
	const [customerId, setCustomerId] = useState(null);
	const [appraisalId, setAppraisalId] = useState(null);
	const [productsIds, setProductsIds] = useState(null);
	const [retailerInfo, setRetailerInfo] = useState(null);
	const [retailerId, setRetailerId] = useState(null);
	const [selectedRetailer, setSelectedRetailer] = useState(null);
	const [receivesRemoteVideo, setReceivesRemoteVideo] = useState(false);
	const [captureCamera, setCaptureCamera] = useState(false);
	const clientId = useParams().id;
	const [callId, setCallId] = useState(null);
	const retailer = sessionStorage.getItem('retailer');
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const dispatch = useDispatch();
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState();
	const [productIndex, setProductIndex] = useState();
	const [imageIndex, setImageIndex] = useState();
	const productSidebar = useRef(null);
	const [boxSize, setBoxSize] = useState(5);

	const getDataSelect = (elements) => {
		const data = [];
		for (const element of elements) {
			let elem = {};
			elem.label = element.name;
			elem.value = element._id;
			data.push(elem);
		}
		return data;
	};

	const handleHangUp = () => {
		localStream.getTracks().forEach(function(track) {
			track.stop();
		});
		const localVideo = document.getElementById('local-video');
		const remoteVideo = document.getElementById('remote-video');
		localVideo.srcObject = null;
		remoteVideo.srcObject = null;
		socket.close();
		setReceivesRemoteVideo(false);
		setCaptureCamera(false);
	};

	const handleReload = () => {
		window.location.reload();
	};

	useEffect(() => {
		if (!retailer) return;
		const retailerData = JSON.parse(sessionStorage.getItem('retailer'));
		setCallId(retailerData.call_id);
		updateCallStatus({
			id: retailerData.call_id,
			answeredBy: user._id,
		}).catch((error) => {});
		getUserRetailerByEmail(retailerData.email)
			.then((res) => {
				if (res.status === 200) {
					getRetailerById(res.data.retailer)
						.then((resp) => {
							if (resp.status === 200) {
								setRetailerInfo({
									...res.data,
									country: retailerData?.country,
									currency: retailerData?.currency,
									retailerName: retailerData?.retailer,
									city: retailerData?.city,
									commissionRetailer: resp.data.commission,
								});
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener los datos del retailer.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: ' ',
					customMessage: 'Llamada a través de webapp',
				});
				getRetailerByName(retailerData.retailer)
					.then((res) => {
						if (res.status === 200) {
							setRetailerId(res.data._id);
							getUserRetailerByRetailer(res.data._id)
								.then((resr) => {
									if (resr.status === 200) {
										setRetailersOptions(getDataSelect(resr.data));
										setRetailers(resr.data);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se han podido obtener los vendedores.',
									});
								});
						}
					})
					.catch((error) => {
						alertError({
							error: error,
							customMessage: 'No se han podido obtener los datos del retailer.',
						});
					});
			});
	}, [retailer]);

	useEffect(() => {
		if (selectedRetailer != null) {
			getRetailerById(retailerId)
				.then((resp) => {
					if (resp.status === 200) {
						setRetailerInfo({
							...retailerInfo,
							country: resp?.data.country,
							currency: resp?.data.currency,
							retailerName: resp?.data.name,
							city: resp?.data.city,
							commissionRetailer: retailerInfo?.commission,
						});
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido obtener los datos del retailer.',
					});
				});
		}
	}, [selectedRetailer]);

	useEffect(() => {
		getMovements()
			.then((res) => {
				if (res.status === 200) {
					setMovements(res.data.filter((item) => item.active));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los movimientos.',
				});
			});
		getBoxMaterials()
			.then((res) => {
				if (res.status === 200) {
					setBoxMaterials(res.data.filter((item) => item.active));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los materiales de la caja.',
				});
			});
		getStrapMaterials()
			.then((res) => {
				if (res.status === 200) {
					setStrapMaterials(res.data.filter((item) => item.active));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los materiales de la correa.',
				});
			});
		getProductStatuses()
			.then((res) => {
				if (res.status === 200) {
					setProductStatuses(res.data.filter((item) => item.active));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los estados del producto.',
				});
			});
	}, []);

	const connectWithPeer = async (peer) => {
		const outboundConnection = new RTCPeerConnection(configuration);

		const handlers = {
			onIceCandidate: async (e) => {
				if (!e.candidate?.address) return;
				await addICERetry(outboundConnection, e.candidate, '(LOCAL)(OUT)');
				socket.emit('ice-candidate', {
					to: peer,
					ice: e.candidate,
					inbound: false,
				});
			},
			setIce: async (iceData) => {
				if (!iceData.inbound) return;
				await addICERetry(outboundConnection, iceData.ice, '(REMOTE)(OUT)');
			},
			answerMade: async (data) => {
				await outboundConnection.setRemoteDescription(
					new RTCSessionDescription(data.answer)
				);
			},
			disconnect: () => {
				outboundConnection.close();
				socket.off('set-ice', handlers.setIce);
				socket.off('answer-made', handlers.answerMade);
			},
		};

		outboundConnection.onicecandidate = handlers.onIceCandidate;

		socket.on('set-ice', handlers.setIce);
		socket.on('answer-made', handlers.answerMade);
		socket.on('disconnect', handlers.disconnect);

		localStream.getTracks().forEach((track) => {
			outboundConnection.addTrack(track, localStream);
		});

		const offer = await outboundConnection.createOffer();
		await outboundConnection.setLocalDescription(
			new RTCSessionDescription(offer)
		);

		socket.emit('call-user', {
			offer,
			to: peer,
		});

		inCall = true;
	};

	const receiveCalls = () => {
		const inboundConnection = new RTCPeerConnection(configuration);

		let peer = '';
		const handlers = {
			onTrack: ({ streams }) => {
				console.log(inboundConnection.getReceivers());
				const remoteVideo = document.getElementById('remote-video');
				if (remoteVideo) {
					remoteVideo.srcObject = streams[0];
					remoteVideo.autoplay = true;
					remoteVideo.playsInline = true;
					setReceivesRemoteVideo(true);
				}
			},
			setIce: async (iceData) => {
				if (iceData.inbound) return;
				await addICERetry(inboundConnection, iceData.ice, ' (REMOTE)(IN)');
			},
			onIceCandidate: async (e) => {
				if (!e.candidate?.address) return;
				await addICERetry(inboundConnection, e.candidate, ' (LOCAL)(IN)');
				socket.emit('ice-candidate', {
					to: peer,
					ice: e.candidate,
					inbound: true,
				});
			},
			callMade: async (data) => {
				peer = data.socket;
				console.log(peer);
				await inboundConnection.setRemoteDescription(
					new RTCSessionDescription(data.offer)
				);
				if (!inCall) {
					connectWithPeer(data.socket);
				}
				const answer = await inboundConnection.createAnswer();
				await inboundConnection.setLocalDescription(
					new RTCSessionDescription(answer)
				);
				socket.emit('make-answer', {
					to: data.socket,
					answer,
					name: retailer.name || '',
					email: retailer.email || '',
					phone: retailer.phone || '',
				});
				socket.on('disconnect', () => {
					inboundConnection.close();
				});
			},
			peerDisconnected: () => {
				inCall = false;
				const remoteVideo = document.getElementById('remote-video');
				if (remoteVideo) {
					remoteVideo.srcObject = null;
				}
				handleHangUp();
			},
		};

		inboundConnection.ontrack = handlers.onTrack;
		inboundConnection.onicecandidate = handlers.onIceCandidate;

		socket.on('set-ice', handlers.setIce);
		socket.on('call-made', handlers.callMade);
		socket.on('peer-disconnected', handlers.peerDisconnected);
	};

	const initStreams = async () => {
		receiveCalls();
		// Create local stream requesting access to video and audio
		localStream = await navigator.mediaDevices.getUserMedia({
			video: true,
			audio: true,
		});

		const localVideo = document.getElementById('local-video');
		if (localVideo) {
			localVideo.srcObject = localStream;
		}
		connectWithPeer(clientId);
		setCaptureCamera(true);
	};

	useEffect(() => {
		socket.connect();
		socket.emit('init');
		socket.on('sessions-list', (data) => {
			console.log('SESSION-LIST here ', data);
		});

		initStreams();
		return () => {
			socket.disconnect();
			socket.off('init');
			socket.off('call-made');
			socket.off('answer-made');
			socket.off('set-ice');
			socket.off('peer-disconnected');
			socket.connect();
			socket.on('init', (data) => {
				dispatch(updateClientID(data.id));
			});
			socket.on('sessions-list', (data) => {
				const list = [];
				for (const id of Object.keys(data)) {
					list.push({ id, ...data[id] });
				}
				dispatch(updateSessions(list));
			});

			socket.emit('init', {
				type: 'agent',
				name: user?.name,
				surname: user?.surname,
				email: user?.email,
			});
			if (localStream) localStream.getTracks().forEach((t) => t.stop());
		};
	}, []);

	const handleChange = (type, element) => (event) => {
		if (type === 'appraisal') {
			setAppraisalData({ ...appraisalData, [element]: event.target.value });
		} else if (type === 'client') {
			setClientData({ ...clientData, [element]: event.target.value });
		}
	};

	const handleChangeProduct = (index, element) => (event) => {
		const data = [...productsData];
		if (
			element === 'warrantyCertificate' ||
			element === 'originalCase' ||
			element === 'instructions'
		) {
			data[index][element] = !data[index][element];
		} else if (element === 'price') {
			data[index][element] = event.target.value;
			const price = parseInt(event.target.value);
			if (retailerInfo?.commissionRetailer)
				data[index].commissionRetailer = price
					? price * retailerInfo.commissionRetailer * 0.01
					: 0;
			else if (selectedRetailer?.commissionRetailer)
				data[index].commissionRetailer = price
					? price * selectedRetailer.commissionRetailer * 0.01
					: 0;
			if (/*retailerInfo.commission &&*/ retailerInfo?.commissionRetailer)
				data[index].commissionUserRetailer = price
					? /*data[index].commissionRetailer*/ price *
					  0.01 *
					  retailerInfo.commission
					: 0;
		} else data[index][element] = event.target.value;
		setProductsData(data);
	};

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

	const saveClient = () => {
		if (!customerId) {
			postUser(clientData)
				.then((res) => {
					if (res.status === 201) {
						saveAppraisal(res.data._id);
						setCustomerId(res.data._id);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar el cliente.',
					});
				});
		} else {
			updateUser(customerId, clientData)
				.then((res) => {
					if (res.status === 200) {
						saveAppraisal(customerId);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage:
							'No se han podido guardar los cambios en el cliente.',
					});
				});
		}
	};

	const saveAppraisal = (idClient) => {
		const data = {
			...appraisalData,
			client: idClient,
			retailerUser: retailerInfo?._id || selectedRetailer,
		};
		if (!appraisalId) {
			postAppraisal(data)
				.then((res) => {
					if (res.status === 201) {
						saveProducts(res.data._id);
						setAppraisalId(res.data._id);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar la tasación.',
					});
				});
		} else {
			updateAppraisal(appraisalId, data)
				.then((res) => {
					if (res.status === 200) {
						saveProducts(appraisalId);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage:
							'No se han podido guardar los cambios en la tasación.',
					});
				});
		}
	};

	const saveProducts = (idQuote) => {
		productsData.forEach((product, index) => {
			if (
				!selectedImages ||
				!selectedImages[index] ||
				!Object.keys(selectedImages[index]).length
			)
				return alertError({
					error: null,
					customMessage: 'Se requiere como mínimo una imagen por producto.',
				});
			const data = { ...product, appraisal: idQuote };
			if (!productsIds || !productsIds[index]) {
				postProduct(
					data,
					selectedImages[index] ? Object.values(selectedImages[index]) : null
				)
					.then((res) => {
						if (res.status === 201) {
							let ids = [];
							if (productsIds) ids = productsIds;
							setProductsIds(ids.concat(res.data._id));
							//setSelectedImages({...selectedImages, [index]: null })
							if (productsData.length - 1 === index)
								alertSuccess({
									title: 'Guardado!',
									customMessage: 'Los datos se han guardado correctamente.',
								});
						}
					})
					.catch((error) => {
						alertError({
							error: error,
							customMessage:
								'No se ha podido guardar el producto ' + index + '.',
						});
					});
			} else {
				updateProduct(
					productsIds[index],
					data,
					selectedImages[index] ? Object.values(selectedImages[index]) : null
				)
					.then((res) => {
						if (res.status === 200) {
							if (productsData.length - 1 === index)
								alertSuccess({
									title: 'Guardado!',
									customMessage: 'Los datos se han guardado correctamente.',
								});
							//setSelectedImages({...selectedImages, [index]: null })
						}
					})
					.catch((error) => {
						alertError({
							error: error,
							customMessage:
								'No se han podido guardar los cambios en el producto ' +
								index +
								'.',
						});
					});
			}
		});
	};

	function capture(index, imgIndex) {
		var canvas = document.getElementById('myCanvas' + index + imgIndex);
		var video = document.getElementById('remote-video');
		let images = selectedImages[index];
		if (images[imgIndex] === null) {
			canvas.getContext('2d').drawImage(video, 0, 0, 480, 640);
			var myImage = canvas.toDataURL('image/png');
			var file = dataURLtoFile(myImage, 'image' + index + imgIndex + '.png');

			images[imgIndex] = file;
			if (!images[imgIndex + 1]) images[imgIndex + 1] = null;
			setSelectedImages({ ...selectedImages, [index]: images });
		} else {
			setPreviewImage(URL.createObjectURL(images[imgIndex]));
			setProductIndex(index);
			setImageIndex(imgIndex);
			setOpenPreviewDialog(true);
		}
	}

	function getFinalPrice(product) {
		if (product?.price) {
			return (
				parseInt(product?.price) +
				(product?.commissionRetailer
					? parseFloat(product?.commissionRetailer?.toFixed(2))
					: 0) +
				(product?.commissionUserRetailer
					? parseFloat(product?.commissionUserRetailer?.toFixed(2))
					: 0)
			).toFixed(2);
		}
		return (0).toFixed(2);
	}

	useEffect(() => {
		setBoxSize(Math.floor((productSidebar?.current?.offsetWidth - 75) / 70));
	}, [productSidebar]);

	return (
		<>
			<div className='content-container row'>
				<div className='video-chat-container col-6'>
					<div className='video-container'>
						<video
							autoPlay
							width='480'
							height='640'
							className='remote-video img-fluid'
							id='remote-video'></video>
						<video
							autoPlay
							muted
							className='local-video'
							id='local-video'></video>
					</div>
					{captureCamera && (
						<div className='icons-container'>
							<button
								onClick={handleReload}
								className='icon-button grey-button'>
								<ReplayIcon
									style={{ height: '20px', width: '20px', color: 'white' }}
								/>
							</button>
							<button onClick={handleHangUp} className='icon-button red-button'>
								<CallEndIcon
									style={{ height: '20px', width: '20px', color: 'white' }}
								/>
							</button>
						</div>
					)}
					{!receivesRemoteVideo && (
						<div className='margin-1'>
							Si pasados unos segundos no puede ver la imagen del usuario, pulse
							el botón recargar
						</div>
					)}
				</div>
				<div className='retailer-container col-6'>
					<Card>
						<CardHeader title='Datos del vendedor' />
						<CardBody>
							{retailerInfo ? (
								<>
									<div>
										<b>Retailer:</b>
										{` ${retailerInfo.retailerName || ''}`}
									</div>
									<div>
										<b>País:</b>
										{` ${retailerInfo.country || ''}`}
									</div>
									<div>
										<b>Divisa:</b>
										{` ${retailerInfo.currency || 'EUR'}`}
									</div>
									<div>
										<b>Población:</b>
										{` ${retailerInfo.city || ''}`}
									</div>
									<div>
										<b>Nombre:</b>
										{` ${retailerInfo.name || ''}`}
									</div>
									<div>
										<b>Email:</b>
										{` ${retailerInfo.email || ''}`}
									</div>
									<div>
										<b>Teléfono:</b>
										{` ${retailerInfo.phone || ''}`}
									</div>
									<div>
										<b>Comisión vendedor:</b>
										{` ${retailerInfo.commission || ''}`}
									</div>
									<div>
										<b>Comisión retailer:</b>
										{` ${retailerInfo.commissionRetailer || ''}`}
									</div>
								</>
							) : (
								<FormControl style={{ width: '100%' }}>
									<Autocomplete
										options={retailersOptions}
										getOptionLabel={(option) => option.label}
										onChange={(event, selected) => {
											if (selected != null) {
												setSelectedRetailer(selected.value);
												setRetailerInfo(
													retailers.find((rtl) => rtl._id === selected.value)
												);
												updateCall({
													id: callId,
													client: selected.value,
												}).catch((error) => {});
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label='Seleccionar vendedor'
												variant='outlined'
											/>
										)}
									/>
								</FormControl>
							)}
						</CardBody>
					</Card>
					<Card>
						<CardHeader title='Datos del cliente' />
						<CardBody>
							<TextField
								id={`name`}
								label='Nombre'
								value={clientData.name}
								onChange={handleChange('client', 'name')}
								InputLabelProps={{
									shrink: true,
								}}
								margin='normal'
								variant='outlined'
								required
							/>
							<TextField
								id={`surname`}
								label='Apellidos'
								value={clientData.surname}
								onChange={handleChange('client', 'surname')}
								InputLabelProps={{
									shrink: true,
								}}
								margin='normal'
								variant='outlined'
								required
							/>
							<TextField
								id={`phone`}
								label='Teléfono'
								value={clientData.phone}
								onChange={handleChange('client', 'phone')}
								InputLabelProps={{
									shrink: true,
								}}
								margin='normal'
								variant='outlined'
								required
							/>
							<TextField
								id={`email`}
								label='Email'
								value={clientData.email}
								onChange={handleChange('client', 'email')}
								InputLabelProps={{
									shrink: true,
								}}
								margin='normal'
								variant='outlined'
								required
							/>
						</CardBody>
					</Card>
					<Card>
						<CardHeader title='Datos de la tasación' />
						<CardBody>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id='demo-simple-select-standard-label'>
									Estado
								</InputLabel>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={appraisalData.status || ''}
									onChange={handleChange('appraisal', 'status')}
									MenuProps={MenuProps}>
									{APPRAISAL_STATUS.map((option) => (
										<MenuItem key={option.id} value={option.id}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<br />
							<br />
							<TextField
								id={`comments`}
								label='Observaciones'
								value={appraisalData.comments}
								onChange={handleChange('appraisal', 'comments')}
								InputLabelProps={{
									shrink: true,
								}}
								multiline={true}
								rows={5}
								margin='normal'
								variant='outlined'
							/>
						</CardBody>
					</Card>
					<Card>
						<CardHeader title='Datos de los productos' />
						<CardBody ref={productSidebar}>
							{productsData.map((product, index) => (
								<div className='card-client-list' key={index}>
									<div>
										<b>Producto {index + 1}</b>
									</div>
									<br />
									<Box
										sx={{
											display: 'grid',
											gap: 1,
											gridTemplateColumns: `repeat(${boxSize},70px)`,
										}}>
										{selectedImages[index] &&
											Object.keys(selectedImages[index])?.map(
												(image, imgIndex) => (
													<div
														className='text-center mt-2'
														id={'canvasContainer' + index + imgIndex}>
														<canvas
															id={'myCanvas' + index + imgIndex}
															key={'myCanvas' + index + imgIndex}
															onClick={() => {
																if (receivesRemoteVideo)
																	capture(index, imgIndex);
															}}
															width='480'
															height='640'
															style={{
																border: 'black 1px solid',
																width: '50px',
																height: '50px',
															}}>
															{/* Your browser does not support the canvas tag. */}
														</canvas>
														{selectedImages[index][imgIndex] && (
															<div
																onClick={() => {
																	setPreviewImage(
																		URL.createObjectURL(
																			selectedImages[index][imgIndex]
																		)
																	);
																	setProductIndex(index);
																	setImageIndex(imgIndex);
																	setOpenPreviewDialog(true);
																}}>
																Ver
															</div>
														)}
													</div>
												)
											)}
									</Box>

									<br />
									<TextField
										id={`year` + index}
										label='Año'
										value={product.year}
										onChange={handleChangeProduct(index, 'year')}
										InputLabelProps={{
											shrink: true,
										}}
										margin='normal'
										variant='outlined'
									/>
									<TextField
										id={`brand` + index}
										label='Marca'
										value={product.brand}
										onChange={handleChangeProduct(index, 'brand')}
										InputLabelProps={{
											shrink: true,
										}}
										margin='normal'
										variant='outlined'
									/>
									<TextField
										id={`model` + index}
										label='Modelo'
										value={product.model}
										onChange={handleChangeProduct(index, 'model')}
										InputLabelProps={{
											shrink: true,
										}}
										margin='normal'
										variant='outlined'
									/>
									<TextField
										id={`reference` + index}
										label='Referencia'
										value={product.reference}
										onChange={handleChangeProduct(index, 'reference')}
										InputLabelProps={{
											shrink: true,
										}}
										margin='normal'
										variant='outlined'
									/>
									<TextField
										id={`serialNumber` + index}
										label='Número de serie'
										value={product.serialNumber}
										onChange={handleChangeProduct(index, 'serialNumber')}
										InputLabelProps={{
											shrink: true,
										}}
										margin='normal'
										variant='outlined'
									/>
									<br />
									<br />
									<FormControl style={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-standard-label'>
											Movimiento
										</InputLabel>
										<Select
											labelId='demo-simple-select-standard-label'
											id='demo-simple-select-standard'
											value={product.movement || ''}
											onChange={handleChangeProduct(index, 'movement')}
											MenuProps={MenuProps}>
											{movements.map((option) => (
												<MenuItem key={option._id} value={option._id}>
													{option.name.es}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<br />
									<br />
									<FormControl style={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-standard-label'>
											Material de la caja
										</InputLabel>
										<Select
											labelId='demo-simple-select-standard-label'
											id='demo-simple-select-standard'
											value={product.boxMaterial || ''}
											onChange={handleChangeProduct(index, 'boxMaterial')}
											MenuProps={MenuProps}>
											{boxMaterials.map((option) => (
												<MenuItem key={option._id} value={option._id}>
													{option.name.es}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<br />
									<br />
									<FormControl style={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-standard-label'>
											Material de la correa
										</InputLabel>
										<Select
											labelId='demo-simple-select-standard-label'
											id='demo-simple-select-standard'
											value={product.strapMaterial || ''}
											onChange={handleChangeProduct(index, 'strapMaterial')}
											MenuProps={MenuProps}>
											{strapMaterials.map((option) => (
												<MenuItem key={option._id} value={option._id}>
													{option.name.es}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<br />
									<br />
									<FormControl style={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-standard-label'>
											Estado del reloj
										</InputLabel>
										<Select
											labelId='demo-simple-select-standard-label'
											id='demo-simple-select-standard'
											value={product.status || ''}
											onChange={handleChangeProduct(index, 'status')}
											MenuProps={MenuProps}>
											{productStatuses.map((option) => (
												<MenuItem key={option._id} value={option._id}>
													{option.name.es}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<br />
									<br />
									<TextField
										id={`priceExpectation` + index}
										label='Expectativa venta'
										value={product.priceExpectation}
										onChange={handleChangeProduct(index, 'priceExpectation')}
										InputLabelProps={{
											shrink: true,
										}}
										margin='normal'
										variant='outlined'
									/>
									<br />
									<br />
									<FormControlLabel
										control={
											<Checkbox
												checked={product.warrantyCertificate}
												onChange={handleChangeProduct(
													index,
													'warrantyCertificate'
												)}
												name='checkWarranty'
											/>
										}
										label='Certificado de garantía'
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={product.instructions}
												onChange={handleChangeProduct(index, 'instructions')}
												name='checkInstructions'
											/>
										}
										label='Manual de instrucciones'
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={product.originalCase}
												onChange={handleChangeProduct(index, 'originalCase')}
												name='checkOriginalCase'
											/>
										}
										label='Estuche original'
									/>
									<br />
									<br />
									<TextField
										id={`price` + index}
										label='Precio'
										type={'number'}
										value={product.price}
										onChange={handleChangeProduct(index, 'price')}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													{CurrencySymbolMap[retailerInfo?.currency] || '€'}
												</InputAdornment>
											),
										}}
										margin='normal'
										variant='outlined'
									/>

									<TextField
										id={`price` + index}
										label='Comisión retailer'
										value={product.commissionRetailer?.toFixed(2)}
										disabled
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													{CurrencySymbolMap[retailerInfo?.currency] || '€'}
												</InputAdornment>
											),
										}}
										margin='normal'
										variant='outlined'
									/>
									<TextField
										id={`price` + index}
										label='Comisión vendedor'
										disabled
										value={product.commissionUserRetailer?.toFixed(2)}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													{CurrencySymbolMap[retailerInfo?.currency] || '€'}
												</InputAdornment>
											),
										}}
										margin='normal'
										variant='outlined'
									/>
									<TextField
										id={`price` + index}
										label='Precio final'
										disabled
										value={getFinalPrice(product)}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													{CurrencySymbolMap[retailerInfo?.currency] || '€'}
												</InputAdornment>
											),
										}}
										margin='normal'
										variant='outlined'
									/>
									<br />
									<br />
									<FormControl style={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-standard-label'>
											Estado del producto
										</InputLabel>
										<Select
											labelId='demo-simple-select-standard-label'
											id='demo-simple-select-standard'
											value={product.priceStatus || ''}
											onChange={handleChangeProduct(index, 'priceStatus')}
											MenuProps={MenuProps}>
											{PRICE_STATUS.map((option) => (
												<MenuItem key={option.id} value={option.id}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<br />
									<br />
								</div>
							))}
							<br />
							<br />
							<Button
								onClick={() => {
									setProductsData(
										productsData.concat({
											priceStatus: 'pending',
											originalCase: false,
											warrantyCertificate: false,
											instructions: false,
											price: 0,
											commissionRetailer: 0,
											commissionUserRetailer: 0,
										})
									);
									setSelectedImages({
										...selectedImages,
										[Object.keys(selectedImages).length]: { 0: null },
									});
								}}
								variant='outlined'
								color='secondary'>
								Añadir producto
							</Button>
						</CardBody>
					</Card>
					<Button
						onClick={() => saveClient()}
						variant='outlined'
						color='primary'>
						Guardar
					</Button>
					<PreviewDialog
						title={`Imagen`}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						deleteImage={() => {
							let images = selectedImages[productIndex];
							images[imageIndex] = null;
							const canvas = document.getElementById(
								`myCanvas${productIndex}${imageIndex}`
							);
							const context = canvas.getContext('2d');
							context.clearRect(0, 0, canvas.width, canvas.height);
							if (!images[imageIndex + 1]) images[imageIndex + 1] = null;
							setSelectedImages({ ...selectedImages, [productIndex]: images });
							setOpenPreviewDialog(false);
						}}
						downloadImage={() => {
							forceDownload(
								previewImage,
								selectedImages[productIndex][imageIndex].name
							);
						}}
						inCall>
						<img className='img-fluid' src={previewImage} alt={''} />
					</PreviewDialog>
				</div>
			</div>
		</>
	);
};

export default CallClient;
