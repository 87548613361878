/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive } from '../../../../_helpers';
import WorkIcon from '@material-ui/icons/Work';
import UsersIcon from '@material-ui/icons/Group';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRange from '@material-ui/icons/DateRange';
import GridOn from '@material-ui/icons/GridOn';
import Settings from '@material-ui/icons/Settings';
import SquareIcon from '@material-ui/icons/CropSquare';
import Storage from '@material-ui/icons/Storage';
import WatchIcon from '@material-ui/icons/Watch';
import PublicIcon from '@material-ui/icons/Public';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CategoryIcon from '@material-ui/icons/Category';
import AssessmentIcon from '@material-ui/icons/Assessment';
import VideocamIcon from '@material-ui/icons/Videocam';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIcon from '@material-ui/icons/Phone';
import { getAppointments } from '../../../../../api/appointments';

export function AsideMenuList({ layoutProps }) {
	const location = useLocation();
	const [unconfirmedAppointments, setUnconfirmedAppointments] = useState(0);
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: '';
	};

	useEffect(() => {
		getAppointments()
			.then((res) => {
				if (res.status === 200) {
					let num = res.data.filter((appointment) => !appointment.confirmed)
						?.length;
					setUnconfirmedAppointments(num);
				}
			})
			.catch((error) => {});
	}, []);

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/* Contenido */}
				{/* begin::section */}
				<li
					className={`menu-item ${getMenuItemActive('/client-list', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/client-list'>
						<span className='svg-icon menu-icon'>
							<VideocamIcon />
						</span>
						<span className='menu-text'>Sell now</span>
					</NavLink>
				</li>

				<li className='menu-section'>
					<h4 className='menu-text'>Contenido</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>

				<li
					className={`menu-item ${getMenuItemActive(
						'/retailers',
						false
					)} ${getMenuItemActive('/editar-retailer', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/retailers'>
						<span className='svg-icon menu-icon'>
							<WorkIcon />
						</span>
						<span className='menu-text'>Retailers</span>
					</NavLink>
				</li>
				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						'/usuarios/clientes'
					)} ${getMenuItemActive(
						'/usuarios/editar-cliente'
					)} ${getMenuItemActive(
						'/usuarios/administradores'
					)} ${getMenuItemActive(
						'/usuarios/editar-administrador'
					)} ${getMenuItemActive(
						'/usuarios/usuarios-retailers'
					)} ${getMenuItemActive('/usuarios/editar-usuario-retailer')}`}
					aria-haspopup='true'
					data-menu-toggle='hover'>
					<NavLink className='menu-link menu-toggle' to='/usuarios/clientes'>
						<span className='svg-icon menu-icon'>
							<UsersIcon />
						</span>
						<span className='menu-text'>Usuarios</span>
						<i className='menu-arrow' />
					</NavLink>
					<div className='menu-submenu '>
						<ul className='menu-subnav'>
							<ul className='menu-subnav'>
								<li className='menu-item menu-item-parent' aria-haspopup='true'>
									<span className='menu-link'>
										<span className='menu-text'>Usuarios</span>
									</span>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/usuarios/clientes'
									)} ${getMenuItemActive('/usuarios/editar-cliente')}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/usuarios/clientes'>
										<span className='svg-icon menu-icon'>
											<UsersIcon />
										</span>
										<span className='menu-text'>Clientes</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/usuarios/administradores'
									)} ${getMenuItemActive('/usuarios/editar-administrador')}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/usuarios/administradores'>
										<span className='svg-icon menu-icon'>
											<UsersIcon />
										</span>
										<span className='menu-text'>Administradores</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/usuarios/usuarios-retailers'
									)} ${getMenuItemActive('/usuarios/editar-usuario-retailer')}`}
									aria-haspopup='true'>
									<NavLink
										className='menu-link'
										to='/usuarios/usuarios-retailers'>
										<span className='svg-icon menu-icon'>
											<UsersIcon />
										</span>
										<span className='menu-text'>Usuarios de retailers</span>
									</NavLink>
								</li>
							</ul>
						</ul>
					</div>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/tasaciones',
						false
					)} ${getMenuItemActive('/editar-tasacion', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/tasaciones'>
						<span className='svg-icon menu-icon'>
							<AccessTimeIcon />
						</span>
						<span className='menu-text'>Tasaciones</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/productos',
						false
					)} ${getMenuItemActive('/editar-producto', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/productos'>
						<span className='svg-icon menu-icon'>
							<Storage />
						</span>
						<span className='menu-text'>Productos</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/citas',
						false
					)} ${getMenuItemActive('/editar-cita', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/citas'>
						<span className='svg-icon menu-icon'>
							<DateRange />
						</span>
						<span className='menu-text justify-content-between'>
							<span>Citas</span>
							{unconfirmedAppointments > 0 && (
								<span
									style={{
										padding: '5px 10px',
										borderRadius: '20%',
										backgroundColor: '#b39743',
										fontWeight: 'bold',
										color: 'white',
									}}>
									{unconfirmedAppointments}
								</span>
							)}
						</span>
					</NavLink>
				</li>
				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						'/informes/tasaciones',
						true
					)} ${getMenuItemActive('/informes/piezas', true)} ${getMenuItemActive(
						'/informes/comisiones',
						true
					)}`}
					aria-haspopup='true'
					data-menu-toggle='hover'>
					<NavLink className='menu-link menu-toggle' to='/informes/tasaciones'>
						<span className='svg-icon menu-icon'>
							<AssignmentIcon />
						</span>
						<span className='menu-text'>Informes</span>
						<i className='menu-arrow' />
					</NavLink>
					<div className='menu-submenu '>
						<ul className='menu-subnav'>
							<ul className='menu-subnav'>
								<li className='menu-item menu-item-parent' aria-haspopup='true'>
									<span className='menu-link'>
										<span className='menu-text'>Informes</span>
									</span>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/informes/llamadas'
									)}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/informes/llamadas'>
										<span className='svg-icon menu-icon'>
											<PhoneIcon />
										</span>
										<span className='menu-text'>Llamadas</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/informes/tasaciones'
									)}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/informes/tasaciones'>
										<span className='svg-icon menu-icon'>
											<AccessTimeIcon />
										</span>
										<span className='menu-text'>Tasaciones</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/informes/piezas'
									)}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/informes/piezas'>
										<span className='svg-icon menu-icon'>
											<WatchIcon />
										</span>
										<span className='menu-text'>Piezas</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/informes/comisiones'
									)}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/informes/comisiones'>
										<span className='svg-icon menu-icon'>
											<LocalAtmIcon />
										</span>
										<span className='menu-text'>Comisiones</span>
									</NavLink>
								</li>
							</ul>
						</ul>
					</div>
				</li>
				<li
					className={`menu-item menu-item-submenu ${getMenuItemActive(
						'/auxiliares/movimientos',
						true
					)} ${getMenuItemActive(
						'/auxiliares/material-caja',
						true
					)} ${getMenuItemActive(
						'/auxiliares/material-correa',
						true
					)} ${getMenuItemActive(
						'/auxiliares/editar-movimento',
						true
					)} ${getMenuItemActive(
						'/auxiliares/editar-material-caja',
						true
					)} ${getMenuItemActive(
						'/auxiliares/editar-material-correa',
						true
					)} ${getMenuItemActive(
						'/auxiliares/tipo-producto'
					)} ${getMenuItemActive(
						'/auxiliares/editar-tipo-producto'
					)} ${getMenuItemActive(
						'/auxiliares/estado-producto',
						true
					)} ${getMenuItemActive(
						'/auxiliares/editar-estado-producto',
						true
					)} ${getMenuItemActive('/auxiliares/paises')} ${getMenuItemActive(
						'/auxiliares/editar-pais'
					)}`}
					aria-haspopup='true'
					data-menu-toggle='hover'>
					<NavLink
						className='menu-link menu-toggle'
						to='/auxiliares/movimientos'>
						<span className='svg-icon menu-icon'>
							<GridOn />
						</span>
						<span className='menu-text'>Auxiliares</span>
						<i className='menu-arrow' />
					</NavLink>
					<div className='menu-submenu '>
						<ul className='menu-subnav'>
							<ul className='menu-subnav'>
								<li className='menu-item menu-item-parent' aria-haspopup='true'>
									<span className='menu-link'>
										<span className='menu-text'>Auxiliares</span>
									</span>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/auxiliares/movimientos'
									)} ${getMenuItemActive('/auxiliares/editar-movimiento')}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/auxiliares/movimientos'>
										<span className='svg-icon menu-icon'>
											<Settings />
										</span>
										<span className='menu-text'>Movimientos</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/auxiliares/material-caja'
									)} ${getMenuItemActive('/auxiliares/editar-material-caja')}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/auxiliares/material-caja'>
										<span className='svg-icon menu-icon'>
											<SquareIcon />
										</span>
										<span className='menu-text'>Material de la caja</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/auxiliares/material-correa'
									)} ${getMenuItemActive(
										'/auxiliares/editar-material-correa'
									)}`}
									aria-haspopup='true'>
									<NavLink
										className='menu-link'
										to='/auxiliares/material-correa'>
										<span className='svg-icon menu-icon'>
											<WatchIcon />
										</span>
										<span className='menu-text'>Material de la correa</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/auxiliares/tipo-producto'
									)} ${getMenuItemActive('/auxiliares/editar-tipo-producto')}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/auxiliares/tipo-producto'>
										<span className='svg-icon menu-icon'>
											<CategoryIcon />
										</span>
										<span className='menu-text'>Tipos de productos</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/auxiliares/estado-producto'
									)} ${getMenuItemActive(
										'/auxiliares/editar-estado-producto'
									)}`}
									aria-haspopup='true'>
									<NavLink
										className='menu-link'
										to='/auxiliares/estado-producto'>
										<span className='svg-icon menu-icon'>
											<AssessmentIcon />
										</span>
										<span className='menu-text'>Estados de productos</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/auxiliares/paises'
									)} ${getMenuItemActive('/auxiliares/editar-pais')}`}
									aria-haspopup='true'>
									<NavLink className='menu-link' to='/auxiliares/paises'>
										<span className='svg-icon menu-icon'>
											<PublicIcon />
										</span>
										<span className='menu-text'>Países</span>
									</NavLink>
								</li>
							</ul>
						</ul>
					</div>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/about', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/about'>
						<span className='svg-icon menu-icon'>
							<AccountBoxIcon />
						</span>
						<span className='menu-text'>About Watchupgrade</span>
					</NavLink>
				</li>
				{/* end:: section */}
			</ul>
			{/* end::Menu Nav */}
		</>
	);
}
