import Swal from 'sweetalert2';

// Error messages from api returned as {message: 'error message text'}
export const logError = ({
	error,
	customMessage = 'No custom message from backoffice',
}) => {
	error?.response?.status
		? console.log(
				'ERROR\n' +
					`Status: ${error.response.status}.\n` +
					`Status error: ${error.response.statusText}.\n` +
					`API Message: ${error.response.data?.message}\n` +
					`Backoffice Message: ${customMessage}`
		  )
		: console.log(
				'ERROR\n' + `Backoffice message: ${customMessage}\n` + `${error}`
		  );
};

export const alertError = ({ error, customMessage }) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		'Se ha producido un error al realizar la operación';

	const params = {
		icon: 'error',
		title: (customMessage?.length < 30 && customMessage) || 'Ups...',
		text: message,
		confirmButtonText: 'Aceptar',
		confirmButtonColor: '#b39743',
	};

	Swal.fire(params).then();
};

export const alertSuccess = ({ title, customMessage } = {}) => {
	const message = customMessage || 'Operación completada con éxito!';

	const params = {
		icon: 'success',
		title,
		text: message,
		confirmButtonText: 'Aceptar',
		confirmButtonColor: '#b39743',
	};

	Swal.fire(params).then();
};

export const alertRefresh = () => {
	const params = {
		icon: 'warning',
		title: 'Conexión perdida...',
		text: 'Por favor, recarga la página',
		padding: '3em',
		background: '#fff url(/images/trees.png)',
		backdrop: 'rgba(0, 0, 0, 0.4)',
		allowOutsideClick: false,
		allowEscapeKey: false,
		confirmButtonText: 'Recargar',
	};

	Swal.fire(params).then((result) => {
		if (result.isConfirmed) {
			window.location.reload();
		}
	});
};

export const alertCall = () => {
	const params = {
		icon: 'info',
		title: 'Nuevo cliente esperando',
		confirmButtonText: 'Aceptar',
		confirmButtonColor: '#b39743',
		timer: 45000,
		timerProgressBar: true,
	};

	return Swal.fire(params).then();
};
