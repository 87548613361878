import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../../components/tables/table';
import {
	getMovements,
	deleteMovement,
	changeStatusMovement,
} from '../../../../../api/movements';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import LoopIcon from '@material-ui/icons/Loop';
import { alertError, alertSuccess } from '../../../../../utils/logger';

function getData(movements) {
	let data = [];
	for (let i = 0; i < movements.length; ++i) {
		const elem = {};
		elem.name = movements[i].name?.es;
		elem.active = movements[i].active;
		elem.id = movements[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function MovementsPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [movementId, setMovementId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [disabledMovement, setDisabledMovement] = useState(false);
	const [refresh, setRefresh] = useState(false);

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setMovementId(cell);
							setDisabledMovement(disabled);
							setOpenConfirmDialog(1);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() =>
							history.push('/auxiliares/editar-movimiento/' + cell)
						}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setMovementId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Movimiento', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getMovements()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los movimientos de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de movimientos'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/auxiliares/editar-movimiento')}>
							Añadir movimiento
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledMovement ? 'activar' : 'desactivar') +
							' este movimiento?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusMovement(movementId, disabledMovement)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledMovement ? 'Activado!' : 'Desactivado!',
											customMessage:
												'Movimiento ' +
												(disabledMovement ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledMovement ? 'activar' : 'desactivar') +
											' el movimiento.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar este movimiento?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteMovement(movementId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage: 'Movimiento eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar el movimiento.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
