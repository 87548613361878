import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all product statuses
export const getProductStatuses = () => {
    return authClient().get(`${API}/product-statuses`)
}

// Get product status by id
export const getProductStatusById = (id) => {
    return authClient().get(`${API}/product-statuses/${id}`)
}

// Update product status
export const updateProductStatus = async (id, productStatus) => {
    return authClient().put(`${API}/product-statuses/${id}`, productStatus)
}

// Save product status
export const postProductStatus = async (productStatus) => {
    return authClient().post(`${API}/product-statuses`, productStatus)
}

// Change status of product status
export const changeStatusProductStatus = async (id, status) => {
    return authClient().put(`${API}/product-statuses/change-status/${id}?status=${status}`)
}

// Delete product status
export const deleteProductStatus = (id) => {
    return authClient().delete(`${API}/product-statuses/${id}`)
}

