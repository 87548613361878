import { /*baseClient,*/ API, authClient } from '../../utils/index'

// Get all box materials
export const getBoxMaterials = () => {
    return authClient().get(`${API}/box-materials`)
}

// Get box material by id
export const getBoxMaterialById = (id) => {
    return authClient().get(`${API}/box-materials/${id}`)
}

// Update box material
export const updateBoxMaterial = async (id, boxMaterial) => {
    return authClient().put(`${API}/box-materials/${id}`, boxMaterial)
}

// Save box material
export const postBoxMaterial = async (boxMaterial) => {
    return authClient().post(`${API}/box-materials`, boxMaterial)

}

// Change status box material
export const changeStatusBoxMaterial = async (id, status) => {
    return authClient().put(`${API}/box-materials/change-status/${id}?status=${status}`)
}

// Delete box material
export const deleteBoxMaterial = (id) => {
    return authClient().delete(`${API}/box-materials/${id}`)
}

