import React, { useEffect } from "react";
// import {Redirect} from "react-router-dom";
// import {LayoutSplashScreen} from "../../../../_metronic/layout";
// import * as auth from "../_redux/authRedux";

// class Logout extends Component {
//   componentDidMount() {
//     this.props.logout();
//   }

//   render() {
//     const { hasAuthToken } = this.props;
//     return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
//     // console.log('Needs to clean redux')
//     // console.log('Needs to clean cookies')
//     // console.log('Redirect to login page')
//     // return <Redirect to="/auth/login" />
//   }
// }

// export default connect(
//   ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
//   auth.actions
// )(Logout);

// import {logout} from "../../../../api/auth/index"
import {logout} from "../../../../api/auth/index"

import {authActions} from '../../Auth/_redux/authActions'
import {useDispatch} from 'react-redux'

function Logout() {

  // Needs to clean cookies so auth doesn't "auto-get credentials"
  console.log('Needs to clean cookies')
  // TO TEST cookie cleaning
  // Server should do the cleaning

  
  // Redirect not working as expected --> if auth logged in, it redirects to dashboard
  console.log('Needs to clean redux')
  const dispatch = useDispatch()


  // Redirect to login page
  console.log('Redirect to login page') 
  /* <Redirect to="/auth/login" /> */
  // Not needed the redirect, when dispatching isLogged = false (Routes)
  // It goes automatically to login page

  useEffect(() => {
    async function clearCookies() {
      await logout()
    }
    clearCookies()
    dispatch(authActions.logoutAction())
  }, [dispatch])
  
  return (
    <>
      <h1>logoutPage this should not show</h1>
      <h1>Should make a timeout with splashScreen animation for better accessibility</h1>
    </>
  )
}

export default Logout
