import { UPDATE_SESSIONS, UPDATE_CLIENT_ID } from './sessionsActions';

const initialState = {
	list: [],
	clientId: null,
};

export function sessions(state = initialState, action) {
	switch (action.type) {
		case UPDATE_SESSIONS:
			return { ...state, list: action.list };
		case UPDATE_CLIENT_ID:
			return { ...state, clientId: action.clientId };
		default:
			return state;
	}
}
