import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	getAppointmentById,
	postAppointment,
	updateAppointment,
	changeStatusAppointment,
} from '../../../../api/appointments';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getUsers } from '../../../../api/users';
import { getAppraisals } from '../../../../api/appraisals';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import DatePicker, { registerLocale } from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';

import es from 'date-fns/locale/es';
registerLocale('es', es);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

const contactMethods = [
	{ id: 'whatsapp', label: 'Whatsapp' },
	{ id: 'email', label: 'Email' },
	{ id: 'phone', label: 'Teléfono' },
];

function getEmptyAppointment() {
	return {
		date: new Date(),
		client: null,
		appraisal: null,
		admin: null,
		contactMethod: 'phone',
		confirmed: true,
		comments: '',
	};
}

export default function EditAppointmentsPage() {
	const [appointment, setAppointment] = useState(getEmptyAppointment());
	const [admins, setAdmins] = useState([]);
	const [clients, setClients] = useState([]);
	const [appraisals, setAppraisals] = useState([]);
	const appointmentId = useParams().id;
	const history = useHistory();
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setAdmins(
						res.data.filter((item) => item.active && item.role === 'admin')
					);
					setClients(
						res.data.filter((item) => item.active && item.role === 'seller')
					);
					getAppraisals()
						.then((res) => {
							if (res.status === 200) {
								setAppraisals(res.data);
								if (appointmentId) {
									getAppointmentById(appointmentId)
										.then((res) => {
											if (res.status === 200) {
												setAppointment({
													...res.data,
													date: new Date(res.data.date),
												});
												disableLoadingData();
												setIsConfirmed(res.data.confirmed);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'No se ha podido obtener la cita.',
											});
											history.push('/citas');
										});
								} else {
									disableLoadingData();
									return;
								}
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'No se han podido obtener las tasaciones.',
							});
							history.push('/citas');
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los usuarios.',
				});
				history.push('/citas');
			});
	}, [appointmentId, disableLoadingData, history]);

	function saveAppointment() {
		if (!appointmentId) {
			postAppointment(appointment)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardada!',
							customMessage: 'La cita se ha creado correctamente.',
						});
						history.push('/citas');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar la cita.',
					});
				});
		} else {
			updateAppointment(appointmentId, appointment)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Guardada!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						});
						history.push('/citas');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const getLabel = (option, field) => {
		if (option[field]) {
			let obj = clients.find((client) => client._id === option[field]);
			return `${obj?.name} ${obj?.surname} - ${obj?.email} - ${obj?.phone}`;
		} else {
			if (option[field] === null) return '';
			return `${option?.name} ${option?.surname} - ${option?.email} - ${option?.phone}`;
		}
	};

	const handleChange = (element) => (event) => {
		setAppointment({ ...appointment, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos de la cita'></CardHeader>
					<CardBody>
						<div className='row'>
							<DatePicker
								className='m-5'
								locale='es'
								dateFormat='dd/MM/yyyy HH:mm'
								placeholderText='Fecha Hora (España):'
								selected={appointment.date}
								popperPlacement='bottom-end'
								showTimeSelect
								timeIntervals={15}
								timeCaption='Hora'
								onChange={(date) => {
									setAppointment({ ...appointment, date });
								}}
							/>
						</div>
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Administrador
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={appointment.admin}
								onChange={handleChange('admin')}
								MenuProps={MenuProps}>
								{admins.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name} {option.surname}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl className='mt-4 mb-3' style={{ width: '100%' }}>
							<Autocomplete
								options={clients}
								getOptionLabel={(option) => getLabel(option, 'client')}
								onChange={(event, selected) => {
									if (selected)
										setAppointment({
											...appointment,
											client: selected?._id,
										});
								}}
								disableClearable
								value={appointment}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Seleccionar cliente'
										variant='outlined'
									/>
								)}
							/>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Método de contacto preferido
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={appointment.contactMethod}
								onChange={handleChange('contactMethod')}
								MenuProps={MenuProps}>
								{contactMethods.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Tasación
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={appointment.appraisal}
								onChange={handleChange('appraisal')}
								MenuProps={MenuProps}>
								{appraisals.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.code}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`comments`}
							label='Observaciones'
							value={appointment.comments}
							onChange={handleChange('comments')}
							InputLabelProps={{
								shrink: true,
							}}
							multiline={true}
							rows={5}
							margin='normal'
							variant='outlined'
						/>
						<br />
						<br />
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/citas')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveAppointment()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar cita
					</Button>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginLeft: 'auto',
						}}>
						<Button
							onClick={() => {
								setOpenConfirmDialog(true);
							}}
							variant='outlined'
							style={{ marginRight: '20px' }}>
							{`${isConfirmed ? 'Cancelar cita' : 'Confirmar cita'}`}
						</Button>
					</div>
				</div>
				<ConfirmDialog
					title={
						'Estás seguro de que quieres ' +
						(isConfirmed ? 'cancelar' : 'confirmar') +
						' esta cita?'
					}
					open={openConfirmDialog}
					setOpen={setOpenConfirmDialog}
					onConfirm={() => {
						changeStatusAppointment(appointmentId, !isConfirmed)
							.then((res) => {
								if (res.status === 200) {
									setIsConfirmed(!isConfirmed);
									alertSuccess({
										title: !isConfirmed ? '¡Confirmada!' : '¡Cancelada!',
										customMessage:
											'Cita ' +
											(!isConfirmed ? 'confirmada' : 'cancelada') +
											' correctamente.',
									});
								}
							})
							.catch((error) => {
								alertError({
									error: error,
									customMessage:
										'No se ha podido ' +
										(!isConfirmed ? 'confirmar' : 'cancelar') +
										' la cita.',
								});
							});
					}}>
					{'Se enviará un email al vendedor informándole del estado de la cita'}
				</ConfirmDialog>
			</>
		);
}
