/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { authActions } from './modules/Auth/_redux/authActions';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import { ErrorPage } from './modules/ErrorsExamples/ErrorPage';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { LayoutSplashScreen } from '../_metronic/layout';
import { getCredentials, refreshTokens } from '../api/auth/index';
import { logError } from '../utils/logger';

export function Routes() {
	// const { isAuthorized } = useSelector(
	//   ({ auth }) => ({
	//     isAuthorized: auth.auth != null,
	//   }),
	//   shallowEqual
	// );
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const isLogged = useSelector(
		(store) => store.authentication?.loggedIn,
		shallowEqual
	);

	// Checking wether we have credentials in cookies before going to login
	useEffect(() => {
		function fetchMyCredentials() {
			setLoading(true);
			setTimeout(async () => {
				try {
					const response = await getCredentials();
					if (response)
						dispatch(authActions.loginActionSuccess(response?.data));
					setLoading(false);
				} catch (error) {
					logError({
						error,
						customMessage: 'Cannot get credentials, please login.',
					});
					// alertError({error})
					dispatch(authActions.logoutAction());
				}
				setLoading(false);
			}, 1000);
		}
		fetchMyCredentials();
	}, [dispatch]);

	// Refresh interval every 15 minutes if logged
	useEffect(() => {
		console.log('ISLOGGED ', isLogged);
		if (isLogged) {
			const interval = setInterval(async () => {
				try {
					await refreshTokens();
				} catch (error) {
					logError({ error, customMessage: 'Cannot refresh tokens.' });
					// alertError({error})
					dispatch(authActions.logoutAction());
				}
			}, 900000);
			return () => clearInterval(interval);
		}
	}, [dispatch, isLogged]);

	// useEffect(() => {
	// 	dispatch(authActions.getCredentials())
	// }, [dispatch])

	return loading ? (
		<LayoutSplashScreen />
	) : (
		<Switch>
			{isLogged === false ? (
				/*Render auth page when auth at `/auth` and not authorized.*/
				<Route>
					<AuthPage />
				</Route>
			) : (
				/*Otherwise redirect to root page (`/`)*/
				<Redirect from='/auth' to='/' />
			)}

			<Route path='/error' component={ErrorPage} />
			<Route path='/logout' component={Logout} />

			{isLogged === false ? (
				/*Redirect to `/auth` when auth is not authorized*/
				<Redirect to='/auth/login' />
			) : (
				<Layout>
					<BasePage />
				</Layout>
			)}
		</Switch>
	);
}
