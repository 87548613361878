// import {all} from "redux-saga/effects";
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';

import { authentication } from '../app/modules/Auth/_redux/authReducer';

import { sessions } from './sessionsRedux/sessionsReducer';

export const rootReducer = combineReducers({
	auth: auth.reducer,
	authentication,
	sessions,
});

export function* rootSaga() {
	// yield all([auth.saga()]);
}
