import React, { useState, useEffect } from 'react'
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Checkbox,
	FormControlLabel,
	InputAdornment,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import {
	getProductById,
	postProduct,
	updateProduct,
} from '../../../../api/products'
import { getMovements } from '../../../../api/movements'
import { getProductTypes } from '../../../../api/productTypes'
import { getBoxMaterials } from '../../../../api/boxMaterials'
import { getStrapMaterials } from '../../../../api/strapMaterials'
import { getProductStatuses } from '../../../../api/productStatuses'
import { getAppraisals } from '../../../../api/appraisals'
import { getRetailers } from '../../../../api/retailers'
import { getUsers } from '../../../../api/users'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import UploadMultipleButtonGroup from '../../../components/customFields/UploadMultipleButtonGroup'
import { deleteMultipleFiles } from '../../../../api/files'
import DatePicker, { registerLocale } from 'react-datepicker'
import { CurrencySymbolMap } from '../../../../constants'
import es from 'date-fns/locale/es'
registerLocale('es', es)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
}

function getEmptyProduct() {
	return {
		serialNumber: '',
		images: [],
		originalCase: false,
		type: '',
		movement: null,
		status: null,
		year: '',
		boxMaterial: null,
		invoiceNumber: '',
		brand: '',
		strapMaterial: null,
		priceExpectation: 0,
		model: '',
		warrantyCertificate: false,
		code: '',
		reference: '',
		instructions: false,
		appraisal: null,
		price: 0,
		priceStatus: 'pending',
	}
}

export const PRICE_STATUS = [
	{ id: 'pending', label: 'Pending' },
	{ id: 'accepted', label: 'Approved' },
	{ id: 'rejected', label: 'Refused' },
]

export default function EditProductsPage() {
	const [product, setProduct] = useState(getEmptyProduct())
	const [selectedImages, setSelectedImages] = useState([])
	const [deletedURLs, setDeletedURLs] = useState([])
	const [movements, setMovements] = useState([])
	const [boxMaterials, setBoxMaterials] = useState([])
	const [strapMaterials, setStrapMaterials] = useState([])
	const [productTypes, setProductTypes] = useState([])
	const [productStatuses, setProductStatuses] = useState([])
	const [appraisals, setAppraisals] = useState([])
	const [retailers, setRetailers] = useState([])
	const [users, setUsers] = useState([])
	const [date, setDate] = useState(new Date())
	const productId = useParams().id
	const history = useHistory()

	const [currency, setCurrency] = useState('€')

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton()

	useEffect(() => {
		getCurrency(product, appraisals, retailers, users)
	}, [product?.appraisal])

	const getCurrency = (product, appraisals, retailers, users) => {
		if (product?.appraisal) {
			const appraisal = appraisals.find(
				(item) => item._id === product.appraisal
			)
			const user = users.find((u) => u._id === appraisal?.retailerUser)
				?.retailer
			const retailer = retailers.find((item) => item._id === user)
			setCurrency(CurrencySymbolMap[retailer?.currency] || '€')
		}
	}

	useEffect(() => {
		getMovements()
			.then((res) => {
				if (res.status === 200) {
					setMovements(res.data.filter((item) => item.active))
					if (!productId) {
						disableLoadingData()
						return
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los movimientos.',
				})
				history.push('/productos')
			})
		getBoxMaterials()
			.then((res) => {
				if (res.status === 200) {
					setBoxMaterials(res.data.filter((item) => item.active))
					if (!productId) {
						disableLoadingData()
						return
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los materiales de la caja.',
				})
				history.push('/productos')
			})
		getStrapMaterials()
			.then((res) => {
				if (res.status === 200) {
					setStrapMaterials(res.data.filter((item) => item.active))
					if (!productId) {
						disableLoadingData()
						return
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los materiales de la correa.',
				})
				history.push('/productos')
			})
		getProductTypes()
			.then((res) => {
				if (res.status === 200) {
					setProductTypes(res.data.filter((item) => item.active))
					if (!productId) {
						disableLoadingData()
						return
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los materiales de la correa.',
				})
				history.push('/productos')
			})
		getProductStatuses()
			.then((res) => {
				if (res.status === 200) {
					setProductStatuses(res.data.filter((item) => item.active))
					if (!productId) {
						disableLoadingData()
						return
					}
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los estados del producto.',
				})
				history.push('/productos')
			})
		getAppraisals()
			.then((resa) => {
				if (resa.status === 200) {
					setAppraisals(resa.data)
					getRetailers()
						.then((resr) => {
							if (resr.status === 200) {
								setRetailers(resr.data)
								getUsers()
									.then((resu) => {
										if (resu.status === 200) {
											setUsers(resu.data)
											if (!productId) {
												disableLoadingData()
												return
											} else {
												getProductById(productId)
													.then((res) => {
														if (res.status === 200) {
															setProduct(res.data)
															setDate(new Date(res.data.createdAt))
															disableLoadingData()
															getCurrency(
																res.data,
																resa.data,
																resr.data,
																resu.data
															)
														}
													})
													.catch((error) => {
														alertError({
															error: error,
															customMessage:
																'No se ha podido obtener el producto.',
														})
														history.push('/productos')
													})
											}
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage: 'No se han podido obtener los users.',
										})
										history.push('/productos')
									})
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'No se han podido obtener los retailers.',
							})
							history.push('/productos')
						})
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener las tasaciones.',
				})
				history.push('/productos')
			})
	}, [productId, disableLoadingData, history])

	function saveProduct() {
		/*if (typeof product.itp === 'string') {
      product.itp = product.itp.includes(',') ? product.itp.replace(',', '.') : product.itp
      if (isNaN(product.itp)) {
        alertError({ error: null, customMessage: 'ITP debe ser un número.' })
        return
      }
      product.itp = parseFloat(product.itp)
    }
    if (typeof product.commission === 'string') {
      product.commission = product.commission.includes(',') ? product.commission.replace(',', '.') : product.commission
      if (isNaN(product.commission)) {
        alertError({ error: null, customMessage: 'La comisión debe ser un número.' })
        return
      }
      product.commission = parseFloat(product.commission)
    }*/
		if (!productId) {
			if (!selectedImages.length) {
				alertError({
					error: null,
					customMessage: 'Por favor, suba una imagen.',
				})
				return
			}
			postProduct(product, selectedImages)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardado!',
							customMessage: 'El producto se ha creado correctamente.',
						})
						history.push('/productos')
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar el producto.',
					})
				})
		} else {
			updateProduct(productId, product, selectedImages)
				.then((res) => {
					if (res.status === 200) {
						deleteMultipleFiles(deletedURLs)
						alertSuccess({
							title: 'Guardado!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						})
						history.push('/productos')
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					})
				})
		}
	}

	const handleChange = (element) => (event) => {
		setProduct({ ...product, [element]: event.target.value })
	}

	if (isLoadingData) return <ContentSkeleton />
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos del producto'></CardHeader>
					<CardBody>
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Tipo
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.type}
								onChange={handleChange('type')}
								MenuProps={MenuProps}
							>
								{productTypes.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name.es}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`year`}
							label='Año'
							value={product.year}
							onChange={handleChange('year')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`brand`}
							label='Marca'
							value={product.brand}
							onChange={handleChange('brand')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`model`}
							label='Modelo'
							value={product.model}
							onChange={handleChange('model')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`reference`}
							label='Referencia'
							value={product.reference}
							onChange={handleChange('reference')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`serialNumber`}
							label='Número de serie'
							value={product.serialNumber}
							onChange={handleChange('serialNumber')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Movimiento
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.movement || ''}
								onChange={handleChange('movement')}
								MenuProps={MenuProps}
							>
								{movements.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name.es}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Material de la caja
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.boxMaterial || ''}
								onChange={handleChange('boxMaterial')}
								MenuProps={MenuProps}
							>
								{boxMaterials.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name.es}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Material de la correa
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.strapMaterial || ''}
								onChange={handleChange('strapMaterial')}
								MenuProps={MenuProps}
							>
								{strapMaterials.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name.es}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Estado del reloj
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.status || ''}
								onChange={handleChange('status')}
								MenuProps={MenuProps}
							>
								{productStatuses.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.name.es}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`invoiceNumber`}
							label='Número de factura'
							value={product.invoiceNumber}
							onChange={handleChange('invoiceNumber')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`priceExpectation`}
							label='Expectativa venta'
							value={product.priceExpectation}
							onChange={handleChange('priceExpectation')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`code`}
							label='Código'
							value={product.code}
							onChange={handleChange('code')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={product.warrantyCertificate}
									onChange={() =>
										setProduct({
											...product,
											warrantyCertificate: !product.warrantyCertificate,
										})
									}
									name='checkWarranty'
								/>
							}
							label='Certificado de garantía'
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={product.instructions}
									onChange={() =>
										setProduct({
											...product,
											instructions: !product.instructions,
										})
									}
									name='checkInstructions'
								/>
							}
							label='Manual de instrucciones'
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={product.originalCase}
									onChange={() =>
										setProduct({
											...product,
											originalCase: !product.originalCase,
										})
									}
									name='checkOriginalCase'
								/>
							}
							label='Estuche original'
						/>
						<br />
						<br />
						<UploadMultipleButtonGroup
							element='imagen'
							fileURLs={product.images}
							setFileURLs={handleChange('images')}
							selectedFiles={selectedImages}
							setSelectedFiles={setSelectedImages}
							deletedURLs={deletedURLs}
							setDeletedURLs={setDeletedURLs}
							isImage
							previewButton
							downloadButton
						/>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Tasación
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.appraisal || ''}
								onChange={handleChange('appraisal')}
								MenuProps={MenuProps}
							>
								{appraisals.map((option) => (
									<MenuItem key={option._id} value={option._id}>
										{option.code}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`price`}
							label='Precio'
							value={product.price}
							onChange={handleChange('price')}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>{currency}</InputAdornment>
								),
							}}
							margin='normal'
							variant='outlined'
						/>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Estado del producto
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={product.priceStatus}
								onChange={handleChange('priceStatus')}
								MenuProps={MenuProps}
							>
								{PRICE_STATUS.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<TextField
							id={`comments`}
							label='Comentarios'
							value={product.comments || ''}
							onChange={handleChange('comments')}
							InputLabelProps={{
								shrink: true,
							}}
							multiline={true}
							rows={5}
							margin='normal'
							variant='outlined'
						/>
						<div style={{ width: '100%' }}>
							<InputLabel id='demo-simple-select-standard-label'>
								Fecha
								<DatePicker
									className='m-5'
									locale='es'
									dateFormat='dd/MM/yyyy HH:mm'
									placeholderText='Fecha Hora'
									selected={date}
									popperPlacement='top-end'
									showTimeSelect
									timeIntervals={15}
									timeCaption='Hora'
									onChange={(date) => {
										setDate(date)
										setProduct({
											...product,
											createdAt: date.toISOString(),
										})
									}}
								/>
							</InputLabel>
						</div>
						<br />
						<br />
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/productos')}
					variant='outlined'
					style={{ marginRight: '20px' }}
				>
					Atrás
				</Button>
				<Button
					onClick={() => saveProduct()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}
				>
					Guardar producto
				</Button>
			</>
		)
}
