import { /*baseClient,*/ API, authClient } from '../../utils/index';

// Get all appointments
export const getAppointments = () => {
	return authClient().get(`${API}/appointments`);
};

// Get appointment by id
export const getAppointmentById = (id) => {
	return authClient().get(`${API}/appointments/${id}`);
};

// Update appointment
export const updateAppointment = async (id, appointment) => {
	return authClient().put(`${API}/appointments/${id}`, appointment);
};

// Change status appointment
export const changeStatusAppointment = async (id, status) => {
	return authClient().put(
		`${API}/appointments/change-status/${id}?status=${status}`
	);
};

// Save appointment
export const postAppointment = async (appointment) => {
	return authClient().post(`${API}/appointments`, appointment);
};

// Delete appointment
export const deleteAppointment = (id) => {
	return authClient().delete(`${API}/appointments/${id}`);
};
