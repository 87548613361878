import React, { useState, useEffect, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import ClientList from './components/client-list';
import CallClient from './components/call-client';
import AppointmentsPage from './modules/ContentManager/appointments/AppointmentsPage';
import EditAppointmentsPage from './modules/ContentManager/appointments/EditAppointmentsPage';
import AppraisalsPage from './modules/ContentManager/appraisals/AppraisalsPage';
import EditAppraisalsPage from './modules/ContentManager/appraisals/EditAppraisalsPage';
import ProductsPage from './modules/ContentManager/products/ProductsPage';
import EditProductsPage from './modules/ContentManager/products/EditProductsPage';
import EditRetailersPage from './modules/ContentManager/retailers/EditRetailersPage';
import RetailersPage from './modules/ContentManager/retailers/RetailersPage';
import SellersPage from './modules/ContentManager/users/sellers/SellersPage';
import EditSellersPage from './modules/ContentManager/users/sellers/EditSellersPage';
import AdministratorsPage from './modules/ContentManager/users/administrators/AdministratorsPage';
import EditAdministratorsPage from './modules/ContentManager/users/administrators/EditAdministratorsPage';
import RetailersUsersPage from './modules/ContentManager/users/retailersUsers/RetailersUsersPage';
import EditRetailersUsersPage from './modules/ContentManager/users/retailersUsers/EditRetailersUsersPage';
import CallsReportsPage from './modules/ContentManager/reports/callsReports/CallsReportsPage';
import AppraisalsReportsPage from './modules/ContentManager/reports/appraisalsReports/AppraisalsReportsPage';
import CommissionsReportsPage from './modules/ContentManager/reports/commissionsReports/CommissionsReportsPage';
import PiecesReportsPage from './modules/ContentManager/reports/piecesReports/PiecesReportsPage';
import MovementsPage from './modules/ContentManager/auxiliaries/movements/MovementsPage';
import EditMovementsPage from './modules/ContentManager/auxiliaries/movements/EditMovementsPage';
import BoxMaterialPage from './modules/ContentManager/auxiliaries/boxMaterials/BoxMaterialsPage';
import EditBoxMaterialPage from './modules/ContentManager/auxiliaries/boxMaterials/EditBoxMaterialsPage';
import StrapMaterialPage from './modules/ContentManager/auxiliaries/strapMaterials/StrapMaterialsPage';
import EditStrapMaterialPage from './modules/ContentManager/auxiliaries/strapMaterials/EditStrapMaterialsPage';
import ProductTypesPage from './modules/ContentManager/auxiliaries/productTypes/ProductTypesPage';
import EditProductTypesPage from './modules/ContentManager/auxiliaries/productTypes/EditProductTypesPage';
import ProductStatusesPage from './modules/ContentManager/auxiliaries/productStatuses/ProductStatusesPage';
import EditProductStatusesPage from './modules/ContentManager/auxiliaries/productStatuses/EditProductStatusesPage';
import CountriesPage from './modules/ContentManager/auxiliaries/countries/CountriesPage';
import EditCountriesPage from './modules/ContentManager/auxiliaries/countries/EditCountriesPage';
import EditAboutPage from './modules/ContentManager/about/EditAboutPage';
import socket from './components/client-list/socket';
import { toAbsoluteUrl } from '../_metronic/_helpers';
import { alertCall, alertRefresh } from '../utils/logger';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
	updateClientID,
	updateSessions,
} from '../redux/sessionsRedux/sessionsActions';
import { useIdleTimer } from 'react-idle-timer';
export default function BasePage() {
	const [clients, setClients] = useState(0);
	const [audioPlaying, setAudioPlaying] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const sessions = useSelector((store) => store.sessions.list);
	const location = useLocation();
	const [inCall, setInCall] = useState(false);
	const timeout = 900000;

	useEffect(() => {
		setInCall(location?.pathname.includes('call-client'));
	}, [location]);

	function connect() {
		socket.connect();
		socket.on('init', (data) => {
			dispatch(updateClientID(data.id));
		});
		socket.on('sessions-list', (data) => {
			const list = [];
			for (const id of Object.keys(data)) {
				list.push({ id, ...data[id] });
			}
			dispatch(updateSessions(list));
		});
		socket.emit('init', {
			type: 'agent',
			name: user?.name,
			surname: user?.surname,
			email: user?.email,
		});
	}

	function disconnect() {
		socket.disconnect();
		socket.off('init');
	}

	const handleOnIdle = () => {
		reset();
		if (!inCall) {
			console.log('Reseting socket connection');
			disconnect();
			connect();
		} else console.log(`Can't reset socket connection => In Call`);
	};

	const { reset } = useIdleTimer({
		timeout,
		onIdle: handleOnIdle,
		events: [
			'mousemove',
			'keydown',
			'wheel',
			'DOMMouseScroll',
			'mousewheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'MSPointerDown',
			'MSPointerMove',
			'visibilitychange',
		],
		element: document,
	});

	useEffect(() => {
		if (sessions) {
			let count = 0;
			let agents = 0;
			sessions.forEach((session) => {
				if (session.type === 'client' && !session.peer) count += 1;
				else if (session.type === 'agent') agents += 1;
			});
			if (count > clients) {
				playAudio();
			} else {
				pauseAudio();
			}
			setClients(count);
		}
	}, [sessions]);

	const playAudio = () => {
		if (!audioPlaying) {
			alertCall().then(() => {
				pauseAudio();
			});
			setTimeout(() => {
				document.getElementById('call_sound').play();
				setAudioPlaying(true);
			}, 200);
		}
	};

	const pauseAudio = () => {
		document.getElementById('call_sound').pause();
		setAudioPlaying(false);
	};

	const handleDisconnection = (error) => {
		alertRefresh();
	};

	// addEventListener version
	window.addEventListener('offline', (event) => {
		handleDisconnection(event);
	});

	// onoffline version
	window.onoffline = (event) => {
		handleDisconnection(event);
	};

	useEffect(() => {
		connect();
		return () => {
			disconnect();
		};
	}, []);

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact from='/' to='/client-list' />}
				{/*Retailers*/}
				<Route from='/client-list' component={ClientList} />
				<Route from='/call-client/:id' component={CallClient} />
				<ContentRoute from='/retailers' component={RetailersPage} />
				<ContentRoute
					from='/editar-retailer/:id?'
					component={EditRetailersPage}
				/>
				{/*Usuarios*/}
				<ContentRoute from='/usuarios/clientes' component={SellersPage} />
				<ContentRoute
					from='/usuarios/editar-cliente/:id?'
					component={EditSellersPage}
				/>
				<ContentRoute
					from='/usuarios/administradores'
					component={AdministratorsPage}
				/>
				<ContentRoute
					from='/usuarios/editar-administrador/:id?'
					component={EditAdministratorsPage}
				/>
				<ContentRoute
					from='/usuarios/usuarios-retailers'
					component={RetailersUsersPage}
				/>
				<ContentRoute
					from='/usuarios/editar-usuario-retailer/:id?'
					component={EditRetailersUsersPage}
				/>
				{/*Tasaciones*/}
				<ContentRoute from='/tasaciones' component={AppraisalsPage} />
				<ContentRoute
					from='/editar-tasacion/:id?'
					component={EditAppraisalsPage}
				/>
				{/*Productos*/}
				<ContentRoute from='/productos' component={ProductsPage} />
				<ContentRoute
					from='/editar-producto/:id?'
					component={EditProductsPage}
				/>
				{/*Citas*/}
				<ContentRoute from='/citas' component={AppointmentsPage} />
				<ContentRoute
					from='/editar-cita/:id?'
					component={EditAppointmentsPage}
				/>
				{/*Informes*/}
				<ContentRoute from='/informes/llamadas' component={CallsReportsPage} />
				<ContentRoute
					from='/informes/tasaciones'
					component={AppraisalsReportsPage}
				/>
				<ContentRoute
					from='/informes/comisiones'
					component={CommissionsReportsPage}
				/>
				<ContentRoute from='/informes/piezas' component={PiecesReportsPage} />
				{/*Auxiliares*/}
				<ContentRoute
					from='/auxiliares/movimientos'
					component={MovementsPage}
				/>
				<ContentRoute
					from='/auxiliares/editar-movimiento/:id?'
					component={EditMovementsPage}
				/>
				<ContentRoute
					from='/auxiliares/material-caja'
					component={BoxMaterialPage}
				/>
				<ContentRoute
					from='/auxiliares/editar-material-caja/:id?'
					component={EditBoxMaterialPage}
				/>
				<ContentRoute
					from='/auxiliares/material-correa'
					component={StrapMaterialPage}
				/>
				<ContentRoute
					from='/auxiliares/editar-material-correa/:id?'
					component={EditStrapMaterialPage}
				/>
				<ContentRoute
					from='/auxiliares/tipo-producto'
					component={ProductTypesPage}
				/>
				<ContentRoute
					from='/auxiliares/editar-tipo-producto/:id?'
					component={EditProductTypesPage}
				/>
				<ContentRoute
					from='/auxiliares/estado-producto'
					component={ProductStatusesPage}
				/>
				<ContentRoute
					from='/auxiliares/editar-estado-producto/:id?'
					component={EditProductStatusesPage}
				/>
				<ContentRoute from='/auxiliares/paises' component={CountriesPage} />
				<ContentRoute
					from='/auxiliares/editar-pais/:id?'
					component={EditCountriesPage}
				/>
				{/* About Watchupgrade */}
				<ContentRoute from='/about' component={EditAboutPage} />
				<Redirect to='error/error-v1' />
			</Switch>
			<audio
				id='call_sound'
				loop
				src={toAbsoluteUrl('/media/call_sound_high.mp3')}
			/>
		</Suspense>
	);
}
