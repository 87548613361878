import { userConstants } from '../../../../constants'
// import {userLogin} from '../../../../api/auth/index'

export const authActions = {
  loginActionSuccess,
  loginActionFailure,
  logoutAction,
  getCredentials
}

// LOGIN

function loginActionFailure ()  {
  console.log('login action failure: ', userConstants.LOGIN_FAILURE)
  return { 
    type: userConstants.LOGIN_FAILURE 
  } 
}
function loginActionSuccess (user)  {
  console.log('login action success: ', userConstants.LOGIN_SUCCESS)
  return {
    type: userConstants.LOGIN_SUCCESS,
    user
  }
}

// LOGOUT

function logoutAction ()  {
  console.log('logout action')
  return {
    type: userConstants.LOGOUT
  }
}

// TO DO --> GET CREDENTIALS VIA COOKIES

function getCredentials () {
  console.log('get credentials via cookies action')
}