// slice 14 because multer adds 13 numbers + 1 -
export const filenameFromURL = url => url.substring(url.lastIndexOf('/')+1).slice(14)

export const forceDownload = (uri, name) => {
	var link = document.createElement('a')
	link.download = name
	link.href = uri
	link.target = '_blank'
	document.body.appendChild(link)
	link.click()
	link.remove()
}