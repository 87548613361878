import { /*baseClient,*/ API, authClient } from '../../utils/index'
import { uploadFilesGetLinks } from "../files";

// Get all products
export const getProducts = () => {
    return authClient().get(`${API}/products`)
}

// Get product by id
export const getProductById = (id) => {
    return authClient().get(`${API}/products/${id}`)
}

// Update product
export const updateProduct = async (id, product, images, oldImages) => {
    if (images) {
        const newFilesURLs = await uploadFilesGetLinks(images, 'products')
        product.images = [...product.images, ...Object.values(newFilesURLs)] 
    }
    return authClient().put(`${API}/products/${id}`, product)
}

// Save product
export const postProduct = async (product, images) => {
    const newFilesURLs = await uploadFilesGetLinks(images, 'products')
    product.images = Object.values(newFilesURLs)
    return authClient().post(`${API}/products`, product)
}

// Delete product
export const deleteProduct = (id) => {
    return authClient().delete(`${API}/products/${id}`)
}
