import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { useSkeleton } from "../../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../../utils/logger"
import { getProductTypeById, postProductType, updateProductType } from '../../../../../api/productTypes'
import MultilanguageTabBlock from '../../../../components/customFields/MultilanguageTabBlock'


function getEmptyProductType() {
  return {
    name: {},
    active: true
  }
}

export default function EditProductTypesPage() {
  const [productType, setProductType] = useState(getEmptyProductType())
  const productTypeId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!productTypeId) {
      disableLoadingData()
      return
    }
    getProductTypeById(productTypeId).then(res => {
      if (res.status === 200) {
        setProductType(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener el tipo de producto.' })
      history.push('/auxiliares/tipo-producto')
    })
  }, [productTypeId, disableLoadingData, history])

  function saveProductType() {
    if (!productTypeId) {
      postProductType(productType).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Guardado!', customMessage: 'El tipo de producto se ha creado correctamente.' })
          history.push('/auxiliares/tipo-producto')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar el tipo de producto.' })
      })
    } else {
      updateProductType(productTypeId, productType).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Guardado!', customMessage: 'Los cambios realizados se han guardado correctamente.' })
          history.push('/auxiliares/tipo-producto')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    let newField = productType[element]
    newField[lang] = event.target.value
    setProductType({ ...productType, [element]: newField })
  }

  const renderMultilanguageTabContent = lang => {
		return (
			<div style = {{ paddingTop: '5px' }}>
        <TextField
            id={`name`}
            label="Tipo de producto"
            value={(productType.name && productType.name[lang.key]) || ''}
            onChange={handleChange('name', lang.key)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />

			</div>
		)
	}

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Datos del tipo de producto'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <br />
          <FormControlLabel
            control={<Checkbox checked={productType.active} onChange={() => setProductType({ ...productType, active: !productType.active })} name="checkActive" />}
            label="Activo"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/auxiliares/tipo-producto')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveProductType()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar tipo de producto
      </Button>
    </>
  );
}

