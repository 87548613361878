import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { useSkeleton } from "../../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../../utils/logger"
import { getMovementById, postMovement, updateMovement } from '../../../../../api/movements'
import MultilanguageTabBlock from '../../../../components/customFields/MultilanguageTabBlock'


function getEmptyMovement() {
  return {
    name: {},
    active: true
  }
}

export default function EditMovementsPage() {
  const [movement, setMovement] = useState(getEmptyMovement())
  const movementId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!movementId) {
      disableLoadingData()
      return
    }
    getMovementById(movementId).then(res => {
      if (res.status === 200) {
        setMovement(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'No se ha podido obtener el movimiento.' })
      history.push('/auxiliares/movimientos')
    })
  }, [movementId, disableLoadingData, history])

  function saveMovement() {
    if (!movementId) {
      postMovement(movement).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Guardado!', customMessage: 'El movimiento se ha creado correctamente.' })
          history.push('/auxiliares/movimientos')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se ha podido guardar el movimiento.' })
      })
    } else {
      updateMovement(movementId, movement).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Guardado!', customMessage: 'Los cambios realizados se han guardado correctamente.' })
          history.push('/auxiliares/movimientos')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'No se han podido guardar los cambios.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    let newField = movement[element]
    newField[lang] = event.target.value
    setMovement({ ...movement, [element]: newField })
  }

  const renderMultilanguageTabContent = lang => {
		return (
			<div style = {{ paddingTop: '5px' }}>
        <TextField
            id={`name`}
            label="Movimiento"
            value={(movement.name && movement.name[lang.key]) || ''}
            onChange={handleChange('name', lang.key)}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />

			</div>
		)
	}

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Datos del movimiento'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <br />
          <FormControlLabel
            control={<Checkbox checked={movement.active} onChange={() => setMovement({ ...movement, active: !movement.active })} name="checkActive" />}
            label="Activo"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/auxiliares/movimientos')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Atrás
      </Button>
      <Button
        onClick={() => saveMovement()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Guardar movimiento
      </Button>
    </>
  );
}

