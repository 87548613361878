import React from "react";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";

import esMessages from "./messages/es";

const allMessages = {
  es: esMessages,
};

export function I18nProvider({ children }) {
  const messages = allMessages['es'];

  return (
    <IntlProvider locale={'es'} messages={messages}>
      {children}
    </IntlProvider>
  );
}
