import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../../_metronic/_partials/controls';
import Table, {
	booleanFormatter,
	buttonsStyle,
} from '../../../../components/tables/table';
import {
	getCountries,
	deleteCountry,
	changeStatusCountry,
} from '../../../../../api/countries';
import { Button, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import LoopIcon from '@material-ui/icons/Loop';
import { alertError, alertSuccess } from '../../../../../utils/logger';

function getData(countries) {
	let data = [];
	for (let i = 0; i < countries.length; ++i) {
		const elem = {};
		elem.name = countries[i].name;
		elem.active = countries[i].active;
		elem.id = countries[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function CountriesPage() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [countryId, setCountryId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [disabledCountry, setDisabledCountry] = useState(false);
	const [refresh, setRefresh] = useState(false);

	function buttonFormatter(cell) {
		const disabled = !data.filter((item) => item.id === cell)[0].active;
		return (
			<>
				<Tooltip title={disabled ? 'Activar' : 'Desactivar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setCountryId(cell);
							setDisabledCountry(disabled);
							setOpenConfirmDialog(1);
						}}>
						<LoopIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/auxiliares/editar-pais/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title='Eliminar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setCountryId(cell);
							setOpenConfirmDialog(1);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'País', sort: true },
		{
			dataField: 'active',
			text: 'Activo',
			align: 'center',
			headerAlign: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getCountries()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener los países de la base de datos.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de países'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/auxiliares/editar-pais')}>
							Añadir país
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={
							'Estás seguro de que quieres ' +
							(disabledCountry ? 'activar' : 'desactivar') +
							' este país?'
						}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusCountry(countryId, disabledCountry)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: disabledCountry ? 'Activado!' : 'Desactivado!',
											customMessage:
												'País ' +
												(disabledCountry ? 'activado' : 'desactivado') +
												' correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											'No se ha podido ' +
											(disabledCountry ? 'activar' : 'desactivar') +
											' el país.',
									});
								});
						}}
					/>
					<ConfirmDialog
						title={'Estás seguro de que quieres eliminar este país?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteCountry(countryId)
								.then((res) => {
									if (res.status === 204) {
										alertSuccess({
											title: 'Borrado!',
											customMessage: 'País eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar el país.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
