/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import socket from './socket';
import { shallowEqual, useSelector } from 'react-redux';
import {
	Card,
	CardHeader,
	CardBody,
	CardHeaderToolbar,
} from '../../../_metronic/_partials/controls';
import Table, { dateFormatter } from '../tables/table';

import { getCalls } from '../../../api/calls';
import { getRetailers } from '../../../api/retailers';
import { getUsers } from '../../../api/users';
import { alertError } from '../../../utils/logger';
import { useHistory } from 'react-router-dom';
import VideocamIcon from '@material-ui/icons/Videocam';
import CallIcon from '@material-ui/icons/Call';
import CallMissedIcon from '@material-ui/icons/CallMissed';

function getData(calls, retailers, userretailers) {
	let data = [];
	for (let i = 0; i < calls.length; ++i) {
		const elem = {};
		elem.date = calls[i].date;
		elem.answeredBy =
			userretailers.find((item) => item._id === calls[i].answeredBy)?.name ||
			null;
		elem.client =
			userretailers.find((item) => item._id === calls[i].client)?.name ||
			'----';
		elem.email =
			userretailers.find((item) => item._id === calls[i].client)?.email ||
			'----';
		elem.retailer =
			retailers.find((item) => item._id === calls[i].retailer)?.name || '----';
		data = data.concat(elem);
	}
	data.reverse();
	return data;
}

const ClientList = () => {
	const [data, setData] = useState([]);
	const [elapsed, setElapsed] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [lastUpdated, setLastUpdated] = useState(new Date());
	const history = useHistory();
	const sessions =
		useSelector((store) => store.sessions.list, shallowEqual) || [];

	/* const clientId = useSelector(
		(store) => store.sessions.clientId,
		shallowEqual
	); */

	const timeout = 300;

	function callFormatter(cell) {
		return cell || <CallMissedIcon />;
	}

	const columns = [
		{
			dataField: 'date',
			text: 'Fecha',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'client', text: 'Vendedor', sort: true },
		{ dataField: 'email', text: 'Email Vendedor', sort: true },
		{ dataField: 'retailer', text: 'Retailer', sort: true },
		{
			dataField: 'answeredBy',
			text: 'Atendida por',
			sort: true,
			align: 'center',
			headerAlign: 'center',
			formatter: callFormatter,
		},
	];

	const update = () => {
		setRefresh(true);
		setElapsed(0);
		setLastUpdated(new Date());
	};

	useEffect(() => {
		if (elapsed === timeout) {
			update();
		}
	}, [elapsed]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setElapsed((elapsed) => elapsed + 1);
		}, 1000);
		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		getCalls()
			.then((res) => {
				if (res.status === 200) {
					getRetailers()
						.then((response) => {
							if (response.status === 200) {
								getUsers()
									.then((resusers) => {
										if (resusers.status === 200) {
											setData(getData(res.data, response.data, resusers.data));
											setRefresh(false);
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												'No se han podido obtener los usuarios de retailers de la base de datos.',
										});
									});
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener los retailers de la base de datos.',
							});
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						'No se han podido obtener las llamadas de la base de datos.',
				});
			});
	}, [refresh]);

	const getMinutes = () => {
		const diff = Math.abs(new Date() - lastUpdated);
		const minutes = Math.floor(diff / 1000 / 60);
		return minutes < 1
			? 'menos de 1 minuto'
			: minutes === 1
			? '1 minuto'
			: minutes + ' minutos';
	};

	return (
		<>
			<Card>
				<CardBody>
					<div className='row'>
						<div className='col'>
							<h3 className='panel-title'>Clientes esperando:</h3>
							<div className='clients-container'>
								{sessions
									? sessions
											.filter((s) => s.type === 'client' && !s.peer)
											.map((s) => {
												return (
													<div className='card-client-list' key={s.id}>
														<div>
															<b>Retailer:</b>
															{` ${s.retailer || ''}`}
														</div>
														<div>
															<b>País:</b>
															{` ${s.country || ''}`}
														</div>
														<div>
															<b>Nombre:</b>
															{` ${s.name || ''}`}
														</div>
														<div>
															<b>Email:</b>
															{` ${s.email || ''}`}
														</div>
														<div>
															<b>Teléfono:</b>
															{` ${s.phone || ''}`}
														</div>
														<div
															style={{
																position: 'absolute',
																right: '5px',
																bottom: '5px',
															}}>
															<VideocamIcon />
														</div>
														<div
															onClick={() => {
																sessionStorage.setItem(
																	'retailer',
																	JSON.stringify(s)
																);
																socket.disconnect();
																socket.off('init');
																socket.off('call-made');
																history.push(`/call-client/${s.id}`);
															}}
															style={{
																cursor: 'pointer',
																width: '150px',
																textAlign: 'center',
																padding: '0.5rem',
																margin: '1em auto 1em auto',
																color: 'white',
																background: '#26aa26',
																borderRadius: '50px',
															}}>
															<b>RESPONDER</b>
															<CallIcon
																style={{
																	marginLeft: '5px',
																	height: '15px',
																	width: '15px',
																}}
															/>
														</div>
													</div>
												);
											})
									: ''}
							</div>
						</div>

						<div className='col'>
							<h3 className='panel-title'>Clientes en llamada:</h3>
							<div className='clients-busy-container'>
								{sessions
									? sessions
											.filter((s) => s.type === 'client' && s.peer)
											.map((s) => {
												return (
													<div className='card-client-list' key={s.id}>
														<div>
															<b>Retailer:</b>
															{` ${s.retailer}`}
														</div>
														<div>
															<b>País:</b>
															{` ${s.country}`}
														</div>
														<div>
															<b>Nombre:</b>
															{` ${s.name}`}
														</div>
														<div>
															<b>Email:</b>
															{` ${s.email}`}
														</div>
														<div>
															<b>Teléfono:</b>
															{` ${s.phone}`}
														</div>
													</div>
												);
											})
									: ''}
							</div>
						</div>
						<div className='col'>
							<h3 className='panel-title'>Agentes disponibles:</h3>
							<div className='agents-container'>
								{sessions
									? sessions
											.filter((s) => s.type === 'agent')
											.map((s) => {
												return (
													<div className='card-client-list' key={s.id}>
														<div>
															<b>Nombre:</b>
															{` ${s.name || ''}  ${s.surname || ''}`}
														</div>
														<div>
															<b>Email:</b>
															{` ${s.email || ''}`}
														</div>
													</div>
												);
											})
									: ''}
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
			<Card>
				<CardHeader title='Informe de llamadas'>
					<CardHeaderToolbar>
						<div>Actualizado hace {getMinutes()}</div>
						<button
							type='button'
							className='btn btn-primary mr-2 ml-5'
							onClick={update}>
							Actualizar
						</button>
					</CardHeaderToolbar>
				</CardHeader>

				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
};

export default ClientList;
