import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { getUserById, postUser, updateUser } from '../../../../../api/users';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../../utils/logger';

function getEmptyAdmin() {
	return {
		name: '',
		surname: '',
		email: '',
		phone: '',
		active: true,
		role: 'admin',
		password: '',
		repeatPassword: '',
	};
}

export default function EditAdministratorsPage() {
	const [admin, setAdmin] = useState(getEmptyAdmin());
	const adminId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!adminId) {
			disableLoadingData();
			return;
		}
		getUserById(adminId)
			.then((res) => {
				if (res.status === 200) {
					const user = res.data;
					delete user.password;
					setAdmin(user);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se ha podido obtener el administrador.',
				});
				history.push('/usuarios/administradores');
			});
	}, [adminId, disableLoadingData, history]);

	function saveAdmin() {
		if (admin.password !== admin.repeatPassword) {
			alertError({
				error: null,
				customMessage: 'Las contraseñas no coinciden.',
			});
			return;
		}
		delete admin.repeatPassword;
		if (!adminId) {
			postUser(admin)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardado!',
							customMessage: 'El administrador se ha creado correctamente.',
						});
						history.push('/usuarios/administradores');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar el administrador.',
					});
				});
		} else {
			updateUser(adminId, admin)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Guardado!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						});
						history.push('/usuarios/administradores');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setAdmin({ ...admin, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos del administrador'></CardHeader>
					<CardBody>
						<TextField
							id={`name`}
							label='Nombre'
							value={admin.name}
							onChange={handleChange('name')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`surname`}
							label='Apellidos'
							value={admin.surname}
							onChange={handleChange('surname')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`phone`}
							label='Teléfono'
							value={admin.phone}
							onChange={handleChange('phone')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`email`}
							label='Email'
							value={admin.email}
							onChange={handleChange('email')}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete='new-password'
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`password`}
							label='Contraseña'
							value={admin.password}
							onChange={handleChange('password')}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete='new-password'
							type='password'
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`repeatPassword`}
							label='Repetir contraseña'
							value={admin.repeatPassword}
							onChange={handleChange('repeatPassword')}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete='new-password'
							type='password'
							margin='normal'
							variant='outlined'
							required
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={admin.active}
									onChange={() => setAdmin({ ...admin, active: !admin.active })}
									name='checkActive'
								/>
							}
							label='Activo'
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/usuarios/administradores')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveAdmin()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar administrador
				</Button>
			</>
		);
}
