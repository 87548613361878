const TYPES = {
	/* start - images */

	/* .jpg */
	'image/jpeg': 'jpeg',
	'jpg': 'jpeg',
	'jpeg': 'jpeg',

	/* .png */
	'image/png': 'png',
	'png': 'png',

	/* .gif */
	'image/gif': 'gif',
	'gif': 'gif',

	/* .bmp */
	'image/bmp': 'bmp',
	'bmp': 'bmp',

	/* end - images */


	/* start - docs */

	/* .csv */
	'text/csv': 'csv',
	'csv': 'csv',

	/* .docx */
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
	// 'application/msword': 'docx',
	'docx': 'docx',

	/* .xlsx */
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
	// 'application/vnd.ms-excel': 'xlsx',
	'xlsx': 'xlsx',

	/* .pdf */
	'application/pdf': 'pdf',
	'pdf': 'pdf',

	/* end - docs */


	/* start - videos */

	/* .mp4 */
	'video/mp4': 'mp4',
	'application/mp4': 'mp4',
	'mp4': 'mp4',

	/* .webm */
	'video/webm': 'webm',
	'audio/webm': 'webm',
	'webm': 'webm',

	/* end - videos */


	/* start - audio */

	/* .mp3 */
	'audio/mpeg': 'mp3',
	'audio/x-mpeg': 'mp3',
	'audio/mpeg3': 'mp3',
	'audio/x-mpeg3': 'mp3',
	'mp3': 'mp3',

	/* end - audio */


	/* start - compressed files */

	/* start - zip */
	'application/zip': 'zip',
	'zip': 'zip',
	/* end - zip */

	/* end - compressed files */


	/* start - office preview */

	/* start - ppt */
	// 'application/vnd.ms-powerpoint': 'ppt',
	// 'ppt': 'ppt',
	/* end - ppt */

	/* start - xls */
	// 'application/vnd.ms-excel': 'xls',
	// 'xls': 'xls',
	/* end - xls */

	/* end - office preview */
}

export const getType = type => TYPES[type]

export const previewableImageTypes = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp'
]

export const previewableVideoTypes = [
	'video/mp4',
	'application/mp4',
	'video/webm',
	'audio/webm'
]

export const previewableAudioTypes = [
	'audio/mpeg',
	'audio/x-mpeg',
	'audio/mpeg3',
	'audio/x-mpeg3'
]

export const previewableDocumentTypes = [
	'application/pdf',
	'text/csv',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel'
]

export const getMimeTypeOfFile = async (file) => {
	/* signature list -> https://en.wikipedia.org/wiki/List_of_file_signatures */
	const getMimeType = (signature) => {
		switch (signature) {
		case '89504E47':
			return 'image/png'
		case '47494638':
			return 'image/gif'
		case 'FFD8FFDB':
		case 'FFD8FFE0':
		case 'FFD8FFE1':
			return 'image/jpeg'
		// case '424D8A7B':	// bmp file signature not always this
		// 	return 'image/bmp'
		case '66747970':
			return 'video/mp4'
		case '1A45DFA3':
			return 'video/webm'
		case '25504446':
			return 'application/pdf'
		case '504B0304':
			return 'application/zip'
		default:
			return undefined
		}
	}

	return await new Promise((resolve) => {
		let fileReader = new FileReader()

		fileReader.onloadend = function (evt) {
			if (evt.target.readyState === FileReader.DONE) {
				const uint = new Uint8Array(evt.target.result)
				let bytes = []

				uint.forEach((byte) => {
					bytes.push(byte.toString(16))
				})

				const hex = bytes.join('').toUpperCase()
				const mimeType = getMimeType(hex)

				resolve(mimeType)
			}
		}

		fileReader.readAsArrayBuffer(file.slice(0, 4))
	})

}