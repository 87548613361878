import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { getUserById, postUser, updateUser } from '../../../../../api/users';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../../utils/logger';

function getEmptySeller() {
	return {
		name: '',
		surname: '',
		email: '',
		phone: '',
		active: true,
		role: 'seller',
	};
}

export default function EditSellersPage() {
	const [seller, setSeller] = useState(getEmptySeller());
	const sellerId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!sellerId) {
			disableLoadingData();
			return;
		}
		getUserById(sellerId)
			.then((res) => {
				if (res.status === 200) {
					setSeller(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se ha podido obtener el cliente.',
				});
				history.push('/usuarios/clientes');
			});
	}, [sellerId, disableLoadingData, history]);

	function saveSeller() {
		if (!sellerId) {
			postUser(seller)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Guardado!',
							customMessage: 'El cliente se ha creado correctamente.',
						});
						history.push('/usuarios/clientes');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se ha podido guardar el cliente.',
					});
				});
		} else {
			updateUser(sellerId, seller)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Guardado!',
							customMessage:
								'Los cambios realizados se han guardado correctamente.',
						});
						history.push('/usuarios/clientes');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setSeller({ ...seller, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Datos del cliente'></CardHeader>
					<CardBody>
						<TextField
							id={`name`}
							label='Nombre'
							value={seller.name}
							onChange={handleChange('name')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`surname`}
							label='Apellidos'
							value={seller.surname}
							onChange={handleChange('surname')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`phone`}
							label='Teléfono'
							value={seller.phone}
							onChange={handleChange('phone')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							iente
							variant='outlined'
							required
						/>
						<TextField
							id={`email`}
							label='Email'
							value={seller.email}
							onChange={handleChange('email')}
							InputLabelProps={{
								shrink: true,
							}}
							autoComplete='new-password'
							margin='normal'
							variant='outlined'
							required
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={seller.active}
									onChange={() =>
										setSeller({ ...seller, active: !seller.active })
									}
									name='checkActive'
								/>
							}
							label='Activo'
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/usuarios/clientes')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveSeller()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar cliente
				</Button>
			</>
		);
}
