export const UPDATE_SESSIONS = 'UPDATE_SESSIONS';
export const UPDATE_CLIENT_ID = 'UPDATE_CLIENT_ID';

export function updateSessions(list) {
	console.log('NEW SESSIONS: ', list);
	return {
		type: UPDATE_SESSIONS,
		list,
	};
}

export function updateClientID(clientId) {
	console.log('NEW CLIENT_ID', clientId);
	return {
		type: UPDATE_CLIENT_ID,
		clientId,
	};
}
